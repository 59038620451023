import React from 'react'
import cn from 'classnames'
import { Title } from '.'
import { Section } from '../ui'
import { AnimateOnReveal } from '../app'

const TextBlock = ({ title, subtitle, number, description, background, className, reverse = false }) => {
  return (
    <Section
      className={cn('TextBlock', background, className)}
      contentClassName={cn('row y-center even', { reverse: reverse })}
    >
      <Title title={title} subtitle={subtitle} number={number} right={reverse} light={background === 'dark'} />
      <AnimateOnReveal>
        <p
          className={cn(
            { 'text-light': background === 'dark' },
            { 'text-primary': background === 'light' },
            { 'mr-32': reverse },
            { 'ml-32': !reverse }
          )}
          dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, '<br/>') }}
        />
      </AnimateOnReveal>
    </Section>
  )
}

export default TextBlock
