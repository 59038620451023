import React from 'react'
import moment from 'moment'
import Countdown from 'react-countdown'

const TimeLeft = ({ auction }) => {
  const Completionist = () => <span>{moment(auction?.end).fromNow()}</span>

  return (
    <div className="TimeLeft">
      {moment().isBefore(auction?.start) ? (
        <>
          <h6 className="subtitle text-right">STARTS IN</h6>
          <Countdown className="timer" date={new Date(auction?.start)} daysInHours>
            <Completionist />
          </Countdown>
        </>
      ) : (
        <>
          <h6 className="subtitle text-right">{moment().isAfter(auction?.end) ? 'FINSIHED' : 'TIME LEFT'}</h6>
          <Countdown className="timer" date={new Date(auction?.end)} daysInHours>
            <Completionist />
          </Countdown>
        </>
      )}
    </div>
  )
}

export default TimeLeft
