import React from 'react'
import { Title } from '../common'
import {
  CarbonFootprintIcon,
  ContractSelectionIcon,
  DemandManagementIcon,
  DemandResponseIcon,
  EnergyProcurementIcon,
} from '../icons'
import { Section } from '../ui'
import { WhatDoWeOfferItem } from './'
import { AnimateOnReveal } from '../app'

const WhatDoWeOffer = ({ data }) => {
  return (
    <Section className="WhatDoWeOffer pt-0">
      <Title className="mb-100" title={data?.uspTitle} subtitle={data?.uspSubtitle} number="02" />
      <AnimateOnReveal className="usps">
        <WhatDoWeOfferItem
          icon={ContractSelectionIcon}
          title={data?.uspTitle1}
          description={data?.uspDescription1}
          to="/service/energy-procurement"
          label={data?.uspButton}
        />
        <WhatDoWeOfferItem
          icon={EnergyProcurementIcon}
          title={data?.uspTitle2}
          description={data?.uspDescription2}
          to="/service/energy-procurement"
          label={data?.uspButton}
        />
        <WhatDoWeOfferItem
          icon={DemandManagementIcon}
          title={data?.uspTitle3}
          description={data?.uspDescription3}
          to="/service/demand-management"
          label={data?.uspButton}
        />
        <WhatDoWeOfferItem
          icon={DemandResponseIcon}
          title={data?.uspTitle4}
          description={data?.uspDescription4}
          to="/service/demand-response"
          label={data?.uspButton}
        />
        <WhatDoWeOfferItem
          icon={CarbonFootprintIcon}
          title={data?.uspTitle5}
          description={data?.uspDescription5}
          to="/service/carbon-footprint"
          label={data?.uspButton}
        />
      </AnimateOnReveal>
    </Section>
  )
}

export default WhatDoWeOffer
