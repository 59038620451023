/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Route, Switch, useHistory, Redirect } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Dashboard, Login, Auction } from '../views/webapp'
import { Footer } from '../components/app'
import Navbar from '../components/auction/Navbar'
import { useAuth } from '../utils/useAuth'

const queryClient = new QueryClient()

const ProtectedApp = () => {
  const history = useHistory()
  const { error, refetch } = useAuth()

  let token = localStorage.getItem('token')

  useEffect(() => {
    refetch()
    if (!token || error?.response?.status === 401) {
      history.push('/auctions/login')
      localStorage.removeItem('token')
    }
  }, [error, token])

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/auctions/" component={Dashboard} />
        <Route path="/auctions/:id" component={Auction} />
        <Redirect from="/" to="/auctions/" />
      </Switch>
      <Footer sheetNum={7} language="english" hideLoadingSpinner />
    </>
  )
}

const WebApp = () => {
  let token = localStorage.getItem('token')

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {token ? (
          <ProtectedApp />
        ) : (
          <>
            <Route path="/auctions/login" component={Login} />
            <Redirect from="/" to="/auctions/login" />
          </>
        )}
      </QueryClientProvider>
    </>
  )
}

export default WebApp
