import React from 'react'
import { get } from 'lodash'
import { useTable, useSortBy } from 'react-table'
import classNames from 'classnames'
import { CaretIcon } from '../icons'

const defaultPropGetter = () => ({})

function Table({
  className,
  columns,
  data,
  initialState,
  getRowProps = defaultPropGetter,
  rowAction,
  emptyMessage,
  ...props
}) {
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, initialState }, useSortBy)

  const headerGroup = headerGroups[1]

  return (
    // <div className={classNames('list-wrapper', className)}>
    <>
      <table className={classNames('List', className)} {...props}>
        <thead className="ListHead">
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                key={column.id}
                column={column}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={classNames(['ListHeadCell'])}
              >
                <div className="list-head-cell-inner row y-center">
                  <span>{column.render('Header')}</span>
                  {column.canSort && (
                    <CaretIcon
                      className={classNames('caret', column.isSorted ? 'opacity-100' : 'opacity-0', {
                        'transform rotate-180': column.isSortedDesc,
                      })}
                      width={8}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        {rows.length > 0 && (
          <tbody className="list-body" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              const onClick = rowAction ? () => rowAction(row.original) : null
              return (
                <tr className="ListRow" {...row.getRowProps(getRowProps(row))} key={row.id} onClick={onClick}>
                  {row.cells.map((cell, index) => {
                    const cellClassNames = get(cell, 'column.options.classNames')
                    return (
                      <td className={classNames('ListCell', [cellClassNames])} {...cell.getCellProps()} key={index}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      {!rows.length && emptyMessage && <p className="text-default mt-64 text-center empty-message">{emptyMessage}</p>}
    </>
  )
}

export default Table
