import React from 'react'
import Icon from './Icon'

const DownloadIcon = (props) => (
  <Icon viewBox="0 2 29 29" {...props}>
    <path
      d="M25.375 18.125v4.833a2.417 2.417 0 0 1-2.417 2.417H6.042a2.417 2.417 0 0 1-2.417-2.417v-4.833M8.459 12.083l6.041 6.042 6.042-6.042M14.5 18.125v-14.5"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default DownloadIcon
