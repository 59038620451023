import React from 'react'
import Icon from './Icon'

const EnergyProcurementIcon = (props) => (
  <Icon viewBox="0 0 54 57" {...props}>
    <path
      d="M10.643 25.827H9.02c-.518 0-.937-.443-.937-.99v-.965c0-.547.42-.99.937-.99h1.623c.518 0 .938.443.938.99v.965c0 .547-.42.99-.938.99zM36.598 25.827h-1.623c-.518 0-.938-.443-.938-.99v-.965c0-.547.42-.99.938-.99h1.623c.518 0 .938.443.938.99v.965c0 .547-.42.99-.938.99z"
      fill="#FFE645"
    />
    <path
      d="M37.78 48.024h-1.951l-7.913-17.83v-9.598h8.8c2.355 0 3.015-3.55.825-4.482-1.487-.634-7.449-3.316-8.988-3.82l1.581-1.636c1.391-1.44.382-3.999-1.557-3.999H16.896c-1.94 0-2.948 2.558-1.557 3.999l1.577 1.633s-7.51 3.189-8.997 3.822c-2.19.933-1.531 4.483.825 4.483h8.815v9.598l-7.938 17.83h-1.94c-1.375 0-1.438 2.317 0 2.317H37.78c1.374 0 1.437-2.317 0-2.317zm-4.391 0l-9.222-11.73 2.562-3.258 6.66 14.988zm-7.693-17.312l-2.959 3.764-.012.015-2.955-3.759.009-.02V14.186h5.917v16.526zm-2.96 7.429l.06.077v-.003l7.713 9.809H14.966l7.77-9.883zm13.98-19.87h-8.8v-3.75l8.8 3.75zm-19.157 0H8.744l8.815-3.756v3.755zm-.663-9.277h11.681l-2.88 2.983v.002h-5.918l-2.883-2.985zm1.842 24.061l2.558 3.254-9.21 11.715 6.652-14.969z"
      fill="#E8F4FF"
    />
    <path
      d="M37.78 48.024h-1.951l-7.913-17.83v-9.598h8.8c2.355 0 3.015-3.55.825-4.482-1.487-.634-7.45-3.316-8.988-3.82l1.58-1.636c1.392-1.44.383-3.999-1.556-3.999h-2.88v2.335h2.88l-2.88 2.983v18.735l1.032 2.323 6.66 14.989v2.317h4.39c1.375 0 1.437-2.317 0-2.317zm-9.864-33.502l8.8 3.748h-8.8v-3.748z"
      fill="#DCEAF7"
    />
    <path
      d="M35.75 27.889c.345 0 .625-.295.625-.66v-.743c.731.043 1.486-.385 1.715-1.158 1.328.856 4.633 2.56 9.875 2.56.807 0 .807-1.318 0-1.318-6.369 0-9.716-2.71-9.762-2.748 0 0-.397-1.688-1.828-1.6v-.966h.341c1.578 0 2.508-1.22 2.73-2.428.223-1.206-.208-2.703-1.671-3.326-.379-.16-1.071-.466-1.873-.818-1.908-.84-4.597-2.022-6.197-2.659l.867-.897C32.326 9.312 31.029 6 28.578 6H16.895c-2.45 0-3.749 3.311-1.994 5.128l.896.928-3.221 1.368c-.75.318-.283 1.54.466 1.223l4.107-1.745c.438-.282.506-.643.204-1.082l-1.577-1.633c-.967-1-.243-2.869 1.12-2.869h11.68c1.348 0 2.097 1.858 1.12 2.87l-1.58 1.636c-.31.467-.227.834.252 1.1 1.115.365 4.688 1.937 7.053 2.977.838.369 1.5.66 1.886.824.858.365 1.028 1.226.913 1.852-.116.627-.579 1.36-1.505 1.36h-8.8c-.345 0-.624.295-.624.66v9.597c0 .096.02.192.058.28l7.914 17.83c.102.231.323.379.565.379h1.951c.59 0 .57.999 0 .999H24.838c-.807 0-.807 1.318 0 1.318H37.78c2.17 0 2.182-3.635 0-3.635h-1.554l-7.618-17.166c2.93.831 8.853 1.961 15.107 1.961 1.344 0 2.703-.052 4.051-.17.805-.071.702-1.385-.103-1.314-7.771.684-15.903-.883-19.122-1.865v-7.555h6.585v.966c-.886-.026-1.712.667-1.712 1.65v.965c0 .998.828 1.657 1.712 1.65v.743c0 .364.28.659.624.659zm-1.087-3.052v-.965c0-.183.14-.331.313-.331h1.622c.173 0 .314.148.314.33v.966c0 .183-.14.33-.314.33h-1.622a.323.323 0 0 1-.313-.33z"
      fill="#060606"
    />
    <path
      d="M29.156 8.745a.625.625 0 0 0-.578-.41H16.896c-.548 0-.828.724-.437 1.13l2.883 2.984c.115.12.275.189.437.189h5.918c.172 0 .34-.078.457-.212l2.86-2.962a.686.686 0 0 0 .142-.72zm-3.716 2.574h-5.406l-1.61-1.666h8.625l-1.61 1.666zM21.323 49.681H7.68c-.589 0-.569-.998 0-.998h1.941a.623.623 0 0 0 .566-.379l7.938-17.83a.69.69 0 0 0 .06-.28v-9.598c0-.364-.28-.659-.625-.659H8.744c-1.035 0-1.73-1.107-1.472-2.123.302-1.182 1.807-1.483 2.746-1.882.749-.318.282-1.541-.467-1.223l-1.865.793c-2.75 1.172-1.95 5.753 1.058 5.753h.426v.967h-.15c-.86 0-1.562.74-1.562 1.65v.965c0 .998.829 1.657 1.713 1.65v.743c0 .852 1.248.852 1.248 0v-.744c.66.03 1.33-.28 1.63-.935 1.043.75 2.78 1.788 4.886 2.444v2.051l-7.71 17.319H7.68c-2.136 0-2.207 3.635 0 3.635h13.643c.808 0 .808-1.319 0-1.319zM10.956 24.837c0 .182-.14.33-.313.33H9.021a.323.323 0 0 1-.314-.33v-.965c0-.183.14-.331.314-.331h1.622c.173 0 .313.148.313.33v.966zm1.25-.794c.065-1.102-.788-1.88-1.787-1.82v-.968h6.516v5.36c-2.25-.766-4.013-1.981-4.73-2.572z"
      fill="#060606"
    />
    <path
      d="M14.966 48.683H30.51c.472 0 .837-.626.48-1.08l-7.712-9.81c-.258-.328-.717-.46-1.021-.073l-7.77 9.883c-.357.454.008 1.08.48 1.08zm7.772-9.512l6.44 8.194H16.297l6.442-8.194zM27.291 14.522v3.748c0 .364.28.66.625.66h8.8a.634.634 0 0 0 .613-.534.667.667 0 0 0-.38-.737l-8.8-3.748c-.49-.118-.777.086-.858.61zm1.25.977L33.5 17.61h-4.96V15.5zM8.51 17.66c-.62.264-.435 1.27.234 1.27h8.816c.344 0 .624-.296.624-.66v-3.755c-.08-.524-.367-.728-.858-.61L8.51 17.658zm8.425-.049h-4.974l4.974-2.119v2.12zM33.39 48.683c.423 0 .748-.526.565-.94l-6.66-14.988c-.3-.451-.649-.498-1.046-.14l-2.562 3.258a.687.687 0 0 0 0 .842l9.221 11.73c.123.156.301.238.481.238zm-8.411-12.39l1.602-2.038 4.167 9.377-5.77-7.338zM18.659 32.401a.628.628 0 0 0-.486.374L11.52 47.744c-.296.667.575 1.3 1.046.7l9.21-11.714a.688.688 0 0 0 0-.842l-2.559-3.254a.608.608 0 0 0-.56-.233zm1.826 3.908l-5.757 7.323 4.158-9.357 1.599 2.034zM19.154 30.621a.686.686 0 0 0 .135.532l2.956 3.76c.25.317.71.317.96 0l2.878-3.66c.115-.096.24-.396.238-.547v-16.52c0-.38-.355-.732-.736-.66H19.89c-.381-.072-.736.28-.736.66v16.435zm4.527-2.645l1.391-1.298v2.596l-1.391-1.298zm1.391-4.223l-1.391-1.297 1.391-1.298v2.595zm-2.334-2.177l-2.016-1.88 2.016-1.881 2.016 1.88-2.016 1.88zm-2.335-.419l1.392 1.299-1.392 1.297v-2.596zm0 5.521l1.392 1.298-1.392 1.298v-2.596zm.319-1.462l2.016-1.88 2.016 1.88-2.016 1.88-2.016-1.88zm4.35-6.983l-1.391-1.298 1.391-1.298v2.596zm-2.334-2.178l-1.297-1.21h2.594l-1.297 1.21zm-.943.88l-1.392 1.298v-2.596l1.392 1.298zm.93 16.524l-2.082-2.649 2.095-1.954 2.08 1.941-2.092 2.662z"
      fill="#060606"
    />
    <path
      d="M38.055 42.233v.302h-1.388V44.738h1.625c.322 1.476.92 2.662 1.826 3.517l.002.003c1.005.935 2.313 1.383 3.875 1.383 1.12 0 2.168-.215 3.138-.65l.295-.132v-2.485l-.7.304a6.615 6.615 0 0 1-2.659.574c-.945 0-1.649-.27-2.167-.771-.425-.421-.76-.995-.986-1.743h3.995V42.535H40.61v-.507l.002-.04h4.865V39.785h-4.59c.238-.884.585-1.529 1.015-1.967.507-.518 1.171-.79 2.039-.79.687 0 1.515.233 2.498.745l.448.235.229-.452.657-1.295.212-.418-.403-.239c-1.099-.65-2.316-.974-3.641-.974-1.575 0-2.89.493-3.899 1.51-.91.916-1.493 2.143-1.779 3.645h-1.595V41.988H38.061l-.006.233v.013z"
      fill="#E8F4FF"
      stroke="#060606"
    />
  </Icon>
)

export default EnergyProcurementIcon
