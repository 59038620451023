import React from 'react'
import Icon from './Icon'

const EmailIcon = (props) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      d="M56 7H8a6.006 6.006 0 0 0-6 6v38a6.006 6.006 0 0 0 6 6h48a6.006 6.006 0 0 0 6-6V13a6.006 6.006 0 0 0-6-6zM20.793 34.609l-10 13a1 1 0 1 1-1.586-1.218l10-13a1 1 0 0 1 1.586 1.218zm33.816 13.184a1 1 0 0 1-1.4-.184l-10-13a1 1 0 1 1 1.586-1.218l10 13a1 1 0 0 1-.186 1.402zm.081-32.069l-22 21a1 1 0 0 1-1.38 0l-22-21a1 1 0 1 1 1.38-1.448L32 34.617l21.31-20.341a1 1 0 0 1 1.38 1.448z"
      fill="currentColor"
    />
  </Icon>
)

export default EmailIcon
