import React from 'react'
import Modal from 'react-modal'

import { SuccessIcon } from '../icons'

const ThankYouModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      className="Modal"
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
    >
      <div className="ThankYou column xy-center">
        <SuccessIcon width="100" />
        <h2 className="text-primary my-24">Go raibh maith agat</h2>
        <h5 className="mb-16 text-primary text-center">
          Your offer has been received and ranked. Please view your offer in the auction results to see where it ranks.
        </h5>
        <h5 className="text-primary text-center">
          You may also place another offer if you would like to improve your bid.
        </h5>
        <div className="button l mt-32" onClick={onRequestClose}>
          Continue
        </div>
      </div>
    </Modal>
  )
}

export default ThankYouModal
