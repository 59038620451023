import React from 'react'
import { AnimatedItem, AnimateOnReveal } from '../app'
import { Title } from '../common'
import Section from '../ui/Section'

const HowCanWeHelp = ({ data }) => {
  return (
    <Section>
      <AnimateOnReveal>
        <AnimatedItem>
          <Title title={data?.openingTitle} subtitle={data?.openingSubtitle} number="01" />
        </AnimatedItem>
        <AnimatedItem delay={0.5}>
          <p className="l mt-16">{data?.openingDescription}</p>
        </AnimatedItem>
      </AnimateOnReveal>
    </Section>
  )
}

export default HowCanWeHelp
