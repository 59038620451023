import React from 'react'
import Icon from './Icon'

const MonitoringIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M44.7 21.66c-4.09-4.983-12.75-10.798-22.2-10.798-9.485 0-18.144 5.855-22.2 10.798-.4.488-.4 1.191 0 1.68 4.187 5.103 12.866 10.798 22.2 10.798 9.665 0 18.287-6.032 22.2-10.798.4-.489.4-1.192 0-1.68zm-13.21.84c0 4.957-4.033 8.99-8.99 8.99-4.958 0-8.991-4.033-8.991-8.99s4.033-8.99 8.99-8.99c4.958 0 8.991 4.033 8.991 8.99zm-28.38 0c1.49-1.548 5.082-4.89 10.125-7.034a11.608 11.608 0 00-.002 14.066C9.4 27.907 5.838 25.34 3.111 22.5zm28.654 7.034a11.608 11.608 0 00.002-14.066C35.6 17.093 39.16 19.66 41.888 22.5c-1.49 1.548-5.081 4.89-10.124 7.034z"
      fill="currentColor"
    />
    <path
      d="M18 22.5c0 2.482 2.018 4.5 4.5 4.5 2.48 0 4.5-2.018 4.5-4.5 0-2.481-2.02-4.5-4.5-4.5a4.505 4.505 0 00-4.5 4.5zm6.352 0a1.855 1.855 0 01-1.853 1.853 1.855 1.855 0 01-1.853-1.853c0-1.021.831-1.853 1.853-1.853s1.853.832 1.853 1.853zM22.5 5.735c.73 0 1.323-.592 1.323-1.323V1.324a1.324 1.324 0 00-2.647 0v3.088c0 .73.593 1.323 1.324 1.323zM30.398 7.506a1.324 1.324 0 001.805-.494l1.761-3.089a1.323 1.323 0 10-2.3-1.31L29.905 5.7a1.324 1.324 0 00.494 1.806zM12.796 7.011a1.324 1.324 0 002.3-1.311l-1.761-3.088a1.324 1.324 0 00-2.3 1.311l1.761 3.088zM22.5 39.265c-.731 0-1.324.592-1.324 1.323v3.088a1.324 1.324 0 102.647 0v-3.088c0-.73-.592-1.323-1.323-1.323zM14.602 37.495a1.324 1.324 0 00-1.806.493l-1.76 3.089a1.323 1.323 0 102.299 1.311l1.76-3.088a1.323 1.323 0 00-.493-1.805zM32.203 37.988a1.324 1.324 0 00-2.3 1.312l1.762 3.088a1.323 1.323 0 102.3-1.311l-1.762-3.089z"
      fill="currentColor"
    />
  </Icon>
)

export default MonitoringIcon
