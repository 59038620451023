import React from 'react'
import Icon from './Icon'

const VerifyIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M32.446 15.36c.687.687.687 1.8 0 2.487L20.654 29.639c-.687.687-1.8.687-2.486 0l-5.614-5.614a1.757 1.757 0 112.486-2.485l4.37 4.37 10.55-10.55a1.758 1.758 0 012.486 0zM45 22.5C45 34.937 34.935 45 22.5 45 10.063 45 0 34.935 0 22.5 0 10.063 10.065 0 22.5 0 34.937 0 45 10.065 45 22.5zm-3.516 0c0-10.494-8.492-18.984-18.984-18.984-10.494 0-18.984 8.492-18.984 18.984 0 10.494 8.492 18.984 18.984 18.984 10.494 0 18.984-8.492 18.984-18.984z"
      fill="currentColor"
    />
  </Icon>
)

export default VerifyIcon
