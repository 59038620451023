import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'

import { Section, Table } from '../ui'

const COLUMNS = [
  {
    id: 'auction-table',
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
        options: {
          classNames: 'text-bold l grow',
        },
      },
      {
        Header: 'Address',
        accessor: 'address',
        options: {
          classNames: 'grow',
        },
      },
      {
        Header: 'Contract Type',
        accessor: 'fixed',
        Cell: ({ value }) => (value ? 'Fixed' : 'Flexible'),
      },
      {
        Header: 'Status',
        id: 'time-left',
        Cell: ({ row }) => {
          let status = ''
          if (moment().isBefore(row?.original?.start)) {
            status = (
              <div className="pill upcoming">
                Upcoming: <small>Starts {moment(row?.original?.start).fromNow()}</small>{' '}
              </div>
            )
          } else if (moment().isAfter(row?.original?.end)) {
            status = (
              <div className="pill finished">
                Completed - <small>Finished {moment(row?.original?.end).fromNow()}</small>{' '}
              </div>
            )
          } else if (moment().isBetween(row?.original?.start, row?.original?.end)) {
            status = (
              <div className="pill in-progress">
                In Progress - <small>Ends {moment(row?.original?.end).fromNow()}</small>
              </div>
            )
          }

          return status
        },
      },
    ],
  },
]

const AuctionTable = ({ className, title, icon: Icon, data, emptyMessage }) => {
  const history = useHistory()

  const rowAction = ({ id }) => {
    history.push(`/auctions/${id}`)
  }

  return (
    <Section className={classNames('AuctionTable', className)}>
      <div className="title-wrapper row y-center mb-32">
        <Icon size={56} />
        <h3 className="text-primary text-bold ml-16">{title}</h3>
      </div>
      <Table columns={COLUMNS} data={data} rowAction={rowAction} emptyMessage={emptyMessage} />
    </Section>
  )
}

export default AuctionTable
