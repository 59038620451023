import { isEmpty, get } from 'lodash'

export function useSubmit() {
  const onSubmit = ({ values, formikBag: { setStatus, setSubmitting }, submitFn, success }) => {
    setStatus({})
    setSubmitting(true)

    const onSuccess = (data) => {
      setSubmitting(false)
      success(data)
    }

    const onError = (data) => {
      const error = get(data, 'response.data', '')
      const deepError = get(data, 'response.data.message[0].messages[0].message', '')

      // TODO: Could also map backend validation to fields here and setErrors here
      if (!isEmpty(deepError)) {
        setStatus({ message: deepError })
      } else if (!isEmpty(error)) {
        setStatus({ message: `${error?.message} - ${error?.statusCode}` })
      }

      setSubmitting(false)
    }

    // TODO: onSettled  setSubmitting(false)... not working
    submitFn({ values, onSuccess, onError })
  }

  return onSubmit
}
