import React from 'react'
import cn from 'classnames'

const Icon = ({ size, viewBox, fill, stroke, className, width, height, forward, ...rest }) => {
  let style = {}

  let iconWidth = width
  let iconHeight = height

  if (size) {
    iconWidth = `${size}px`
    iconHeight = `${size}px`
  }

  return (
    <svg
      className={cn('Icon', className)}
      style={{ width: iconWidth, height: iconHeight, ...style }}
      viewBox={viewBox}
      {...rest}
    />
  )
}

Icon.defaultProps = {
  viewBox: '0 0 32 32',
  // width: '24px',
  // height: '24px',
}

export default Icon
