import React from 'react'
import cn from 'classnames'
import { AnimateOnReveal } from '../app'

const Title = ({ title, subtitle, number, className, right = false, light = false, ...rest }) => {
  return (
    <AnimateOnReveal className={cn('Title align-self-top', className, { right: right }, { light: light })} {...rest}>
      <div className="number">{number}</div>
      <h6 className="subtitle">{subtitle}</h6>
      <h2 className="title">{title}</h2>
    </AnimateOnReveal>
  )
}

export default Title
