import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../utils/useAuth'
import { LogoAndTextIcon } from '../icons'

const Navbar = () => {
  const history = useHistory()
  const { data } = useAuth()

  const onSignOut = () => {
    localStorage.removeItem('token')
    history.push('/auctions/login')
  }

  return (
    <div>
      <nav className="Navbar" style={{ position: 'relative', height: 120 }}>
        <div className="content navbar-content row space-between y-center" style={{ display: 'flex' }}>
          <Link className="logo" to="/auction">
            <LogoAndTextIcon />
          </Link>
          <div className="row y-center">
            <div className="column x-right">
              <span className="mb-4">Welcome {data?.username}</span>
              <div className="link s underline" onClick={onSignOut}>
                Sign Out
              </div>
            </div>
            {data?.avatar?.url && <img className="avatar ml-24 flex xy-center" src={data?.avatar?.url} alt="avatar" />}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
