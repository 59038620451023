import React from 'react'
import cn from 'classnames'

const Section = ({ className, id, contentClassName, children }) => {
  return (
    <section className={cn('Section', className)} id={id}>
      <div className={cn('content', contentClassName)}>{children}</div>
    </section>
  )
}

export default Section
