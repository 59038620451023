import React from 'react'
import Icon from './Icon'

const ChevronIcon = (props) => (
  <Icon viewBox="0 0 9 26" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.866.141A1.5 1.5 0 0 1 2.86.866L8.197 12.31a2.5 2.5 0 0 1-.037 2.19L2.837 24.96A1.5 1.5 0 0 1 .163 23.6l5.212-10.243L.141 2.134A1.5 1.5 0 0 1 .866.141z"
      fill="#DADADA"
    />
  </Icon>
)

export default ChevronIcon
