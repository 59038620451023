import React from 'react'
import { motion } from 'framer-motion'

const bottomLineWrapper = {
  closed: {
    transform: 'matrix(1,0,0,1,0,0)',
  },
  open: {
    transform:
      'matrix(0.7071067690849304,0.7071067690849304,-0.7071067690849304,0.7071067690849304,18.564037322998047,-6.445562362670898)',
  },
}

const bottomLine = {
  closed: {
    d: 'M16.5,17.827999114990234 C16.5,17.827999114990234 7.5,17.827999114990234 7.5,17.827999114990234',
  },
  open: {
    d: 'M 16.5 17.828 C 16.5 17.828 12.227 17.828 9.57 17.828',
  },
}

const middleLineWrapper = {
  closed: {
    transform: 'matrix(1,0,0,1,0,0)',
  },
  open: {
    transform:
      'matrix(0.7071067690849304,-0.7071067690849304,0.7071067690849304,0.7071067690849304,-5.08994197845459,12.047792434692383)',
  },
}

const middleLine = {
  closed: {
    d: ' M20,12.17199993133545 C20,12.17199993133545 4,12.17199993133545 4,12.17199993133545',
  },
  open: {
    d: ' M20,12.17199993133545 C20,12.17199993133545 4,12.17199993133545 4,12.17199993133545',
  },
}

const topLineWrapper = {
  closed: {
    transform: 'matrix(1,0,0,1,0,0)',
  },
  open: {
    transform:
      'matrix(0.7071067690849304,0.7071067690849304,-0.7071067690849304,0.7071067690849304,5.493860244750977,-3.4231185913085938)',
  },
}

const topLine = {
  closed: {
    d: '  M16.5,6.442999839782715 C16.5,6.442999839782715 7.5,6.442999839782715 7.5,6.442999839782715',
  },
  open: {
    d:
      '  M14.430000305175781,6.442999839782715 C11.77299976348877,6.442999839782715 7.5,6.442999839782715 7.5,6.442999839782715',
  },
}

const Hamburger = ({ isMenuOpen }) => {
  const animate = isMenuOpen ? 'open' : 'closed'
  return (
    <>
      <motion.svg viewBox="2 2 20 20" width="28" height="28">
        <motion.g>
          <motion.g>
            <motion.g
              opacity="1"
              variants={bottomLineWrapper}
              initial={false}
              animate={animate}
              transition={{
                default: { duration: 0.5 },
                stroke: { duration: 0.5 },
              }}
            >
              <motion.path
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity="0"
                stroke="currentColor"
                stroke-opacity="1"
                strokeWidth="2"
                variants={bottomLine}
                animate={animate}
                transition={{
                  default: { duration: 0.5, ease: 'easeInOut' },
                  stroke: { duration: 0.5, ease: 'easeInOut' },
                }}
              ></motion.path>
            </motion.g>
            <motion.g
              opacity="1"
              transform="matrix(1,0,0,1,0,0)"
              variants={middleLineWrapper}
              initial={false}
              animate={animate}
              transition={{
                default: { duration: 0.5 },
                stroke: { duration: 0.5 },
              }}
            >
              <motion.path
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="currentColor"
                stroke-opacity="1"
                strokeWidth="2"
                variants={middleLine}
                animate={animate}
                transition={{
                  default: { duration: 0.5, ease: 'easeInOut' },
                  stroke: { duration: 0.5, ease: 'easeInOut' },
                }}
              ></motion.path>
            </motion.g>
            <motion.g
              opacity="1"
              transform="matrix(1,0,0,1,0,0)"
              variants={topLineWrapper}
              initial={false}
              animate={animate}
              transition={{
                default: { duration: 0.5 },
                stroke: { duration: 0.5 },
              }}
            >
              <motion.path
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="currentColor"
                stroke-opacity="1"
                strokeWidth="2"
                variants={topLine}
                animate={animate}
                transition={{
                  default: { duration: 0.5, ease: 'easeInOut' },
                  stroke: { duration: 0.5, ease: 'easeInOut' },
                }}
              ></motion.path>
            </motion.g>
          </motion.g>
        </motion.g>
      </motion.svg>
    </>
  )
}

export default Hamburger
