import React from 'react'
import { AuctionTable } from '../../components/auction'
import { EnergyIcon, GasIcon } from '../../components/icons'
import { Section, View } from '../../components/ui'
import { useGetAuctions } from '../../utils/useAuction'

const Dashboard = () => {
  const query = useGetAuctions()

  const { data: auctions } = query

  const energyAuctions = auctions?.filter(({ type }) => type === 'Electricity')
  const gasAuctions = auctions?.filter(({ type }) => type === 'Gas')

  return (
    <View className="Dashboard">
      <Section className="grey">
        <h2 className="title">Auctions</h2>
      </Section>
      {auctions && (
        <AuctionTable
          title="Electricity Auctions"
          icon={EnergyIcon}
          data={energyAuctions}
          emptyMessage="You do not have any electricity auctions to place an offer on at the moment."
        />
      )}
      {auctions && (
        <AuctionTable
          className="mb-32 pt-12"
          title="Gas Auctions"
          icon={GasIcon}
          data={gasAuctions}
          emptyMessage="You do not have any gas auctions to place an offer on at the moment."
        />
      )}
    </View>
  )
}

export default Dashboard
