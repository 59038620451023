import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import cookieCutter from 'cookie-cutter'

import { CookieConsent, Footer, Navbar } from '../components/app'
import { About, Contact, Home, Service } from '../views/website'

import {
  energyIcons,
  energyIcons2,
  energyKeywords,
  energyDescription,
  demandManagementIcons,
  demandManagementKeywords,
  demandManagementDescription,
  demandResponseIcons,
  demandResponseKeywords,
  demandResponseDescription,
  carbonFootprintIcons,
  carbonFootprintKeywords,
  carboonFootprintDescription,
} from '../utils/constants'

import energryVideo from '../assets/videos/energy.mp4'
import demandManagementVideo from '../assets/videos/demand-management.mp4'
import demandResponseVideo from '../assets/videos/demand-response.mp4'
import carbonFootprintVideo from '../assets/videos/carbon-footprint.mp4'

const Website = () => {
  const [language, setLanguage] = useState()
  const [hasConsent, setHasConsent] = useState(null)

  useEffect(() => {
    setHasConsent(cookieCutter.get('consent_cookie') || null)
  }, [])

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      setLanguage(savedLanguage)
    } else {
      setLanguage('english')
    }
  }, [])

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)
  }

  return (
    <>
      <Navbar language={language} changeLanguage={changeLanguage} sheetNum={8} hideLoadingSpinner />
      <Switch>
        <Route exact path="/" render={(props) => <Home {...props} sheetNum={1} language={language} />} />
        <Route
          exact
          path="/service/energy-procurement"
          render={(props) => (
            <Service
              {...props}
              sheetNum={2}
              language={language}
              video={energryVideo}
              backgroundPosition="bottom"
              icons={energyIcons}
              icons2={energyIcons2}
              keywords={energyKeywords}
              description={energyDescription}
            />
          )}
        />
        <Route
          exact
          path="/service/demand-management"
          render={(props) => (
            <Service
              {...props}
              sheetNum={3}
              language={language}
              video={demandManagementVideo}
              icons={demandManagementIcons}
              keywords={demandManagementKeywords}
              description={demandManagementDescription}
            />
          )}
        />
        <Route
          exact
          path="/service/demand-response"
          render={(props) => (
            <Service
              {...props}
              sheetNum={4}
              language={language}
              video={demandResponseVideo}
              // backgroundPosition="bottom"
              icons={demandResponseIcons}
              keywords={demandResponseKeywords}
              description={demandResponseDescription}
            />
          )}
        />
        <Route
          exact
          path="/service/carbon-footprint"
          render={(props) => (
            <Service
              {...props}
              sheetNum={5}
              language={language}
              video={carbonFootprintVideo}
              icons={carbonFootprintIcons}
              keywords={carbonFootprintKeywords}
              description={carboonFootprintDescription}
            />
          )}
        />
        <Route exact path="/about" render={(props) => <About {...props} sheetNum={6} language={language} />} />
        <Route exact path="/contact" render={(props) => <Contact {...props} sheetNum={7} language={language} />} />
      </Switch>
      <Footer sheetNum={7} language={language} hideLoadingSpinner />
      <CookieConsent hasConsent={hasConsent} setHasConsent={setHasConsent} />
    </>
  )
}

export default Website
