import React from 'react'
import { Table } from '../ui'

const COLUMNS = (columns, supplierName) => [
  {
    id: 'auction-table',
    columns: [
      {
        Header: 'Rank',
        accessor: 'rank',
        sortType: (a, b) => {
          const valueA = parseInt(a?.original?.rank) || 99
          const valueB = parseInt(b?.original?.rank) || 99
          return valueA - valueB
        },
      },
      {
        Header: 'Supplier',
        accessor: 'supplier',
        Cell: ({ value }) => {
          return value === supplierName ? (
            <div>
              <span>{value}</span>
              <small className="ml-8 l text-secondary">(Your Offer)</small>
            </div>
          ) : (
            value
          )
        },
      },
      ...columns,
    ],
  },
]

const ResultTable = ({ className, title, data, columns, supplierName }) => {
  const initialState = {
    sortBy: [{ id: 'rank', desc: false }],
  }

  return (
    <div className={className}>
      <div className="title-wrapper row y-center mb-16">
        <h3 className="subtitle text-bold ml-8" style={{ fontSize: 24 }}>
          {title}
        </h3>
      </div>
      <Table className="results" columns={COLUMNS(columns, supplierName)} data={data} initialState={initialState} />
    </div>
  )
}

export default ResultTable
