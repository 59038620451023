import React from 'react'
import { BrowserRouter as Router, useLocation, Route } from 'react-router-dom'
import { GoogleAnalytics, ScrollToTop } from './components/app'
import { QueryParamProvider } from 'use-query-params'

import './styles/index.css'
import { WebApp, Website } from './apps'

const Apps = () => {
  const { pathname } = useLocation()
  const isApp = pathname.includes('auction')
  return isApp ? <WebApp /> : <Website />
}

function App() {
  return (
    <>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <GoogleAnalytics />
          <ScrollToTop />
          <Apps />
        </QueryParamProvider>
      </Router>
    </>
  )
}

export default App
