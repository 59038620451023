import React from 'react'
import Icon from './Icon'

const DemandResponseIcon = (props) => (
  <Icon viewBox="0 0 52 52" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M31.532 5.928v4.593c7.533 2.353 13 9.384 13 17.692 0 10.235-8.297 18.532-18.532 18.532l-2.213 2.212L26 51.17c12.679 0 22.957-10.278 22.957-22.957 0-10.772-7.419-19.81-17.425-22.285z"
        fill="#008CCB"
      />
      <path
        d="M7.468 28.213c0-8.308 5.467-15.34 13-17.692V5.928C10.462 8.404 3.042 17.441 3.042 28.213 3.042 40.892 13.321 51.17 26 51.17v-4.425c-10.235 0-18.532-8.297-18.532-18.532zM28.213.83h-4.426v27.383h4.426V.83z"
        fill="#3AAADC"
      />
      <path
        d="M44.647 13.444C41.385 9.33 36.8 6.376 31.731 5.122a.83.83 0 0 0-1.029.806v4.593a.83.83 0 0 0 .583.792c7.427 2.32 12.417 9.111 12.417 16.9 0 9.761-7.94 17.702-17.702 17.702a17.72 17.72 0 0 1-11.345-4.114.83.83 0 0 0-1.065 1.273 19.381 19.381 0 0 0 12.41 4.5c10.676 0 19.362-8.685 19.362-19.361a19.2 19.2 0 0 0-3.84-11.576 19.549 19.549 0 0 0-9.16-6.716V7.014a22.078 22.078 0 0 1 15.766 21.199C48.128 40.414 38.2 50.34 26 50.34S3.873 40.414 3.873 28.213A22.078 22.078 0 0 1 19.638 7.014v2.907a19.55 19.55 0 0 0-9.16 6.716 19.201 19.201 0 0 0-3.84 11.576c0 4.53 1.599 8.937 4.501 12.41a.83.83 0 1 0 1.273-1.065 17.72 17.72 0 0 1-4.114-11.345c0-7.789 4.99-14.58 12.418-16.9a.83.83 0 0 0 .582-.792V5.928a.83.83 0 0 0-1.03-.806c-5.066 1.254-9.653 4.21-12.915 8.322-3.363 4.24-5.14 9.346-5.14 14.769a23.631 23.631 0 0 0 6.967 16.82A23.632 23.632 0 0 0 26 52a23.632 23.632 0 0 0 16.82-6.967 23.632 23.632 0 0 0 6.967-16.82c0-5.423-1.777-10.53-5.14-14.77z"
        fill="#000"
      />
      <path
        d="M23.787 29.043h4.426a.83.83 0 0 0 .83-.83V.83a.83.83 0 0 0-.83-.83h-4.426a.83.83 0 0 0-.83.83v27.383c0 .458.372.83.83.83zm.83-27.383h2.766v25.723h-2.766V1.66z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h52v52H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default DemandResponseIcon
