import React from 'react'
import Icon from './Icon'

const CaretIcon = (props) => (
  <Icon viewBox="0 0 8 5" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.014.047c.89 0 1.337 1.078.707 1.707l-2.3 2.3a1 1 0 0 1-1.414 0l-2.3-2.3C.077 1.124.523.047 1.414.047h4.6z"
      fill="currentColor"
    />
  </Icon>
)

export default CaretIcon
