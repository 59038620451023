import React from 'react'
import Icon from './Icon'

const ArrowIcon = ({ props }) => {
  return (
    <Icon viewBox="0 0 12 12" {...props}>
      <path
        d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 0 0 0-1.414z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default ArrowIcon
