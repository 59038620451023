import React from 'react'

const Field = ({ label, name, type = 'text', placeholder, component, formik, required, ...rest }) => {
  const Component = component ? component : 'input'
  return (
    <div className="Field">
      <label htmlFor={name}>
        <div>
          {label}: {required && <sup className="star">*</sup>}
        </div>
        {formik.touched[name] && formik.errors[name] ? (
          <div className="error-message">{formik.errors[name]}</div>
        ) : null}
      </label>
      <Component
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={formik.handleChange}
        value={formik.values[name]}
        {...rest}
      />
    </div>
  )
}

export default Field
