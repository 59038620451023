import React from 'react'
import { motion } from 'framer-motion'

const AnimatedItem = ({ key, children, delay = 0, ...etc }) => {
  const item = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      // transition: {
      //   delay: delay,
      // },
    },
  }

  return (
    <motion.div key={key} variants={item} {...etc}>
      {children}
    </motion.div>
  )
}

export default AnimatedItem
