import React from 'react'
import Icon from './Icon'

const MapIcon = (props) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M32 1C20.694 1 9 9.639 9 24.095 9 39.24 30.383 60.797 31.293 61.707a.997.997 0 0 0 1.414 0C33.617 60.797 55 39.24 55 24.095 55 9.639 43.306 1 32 1zm0 31a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"
    />
  </Icon>
)

export default MapIcon
