import { protectedRequest } from './api'

import { useQuery } from 'react-query'

const getAuctions = async () => {
  return await protectedRequest('GET', 'auctions').then((res) => res.data)
}

const getAuction = async (id) => {
  return await protectedRequest('GET', `auctions/${id}`).then((res) => res.data)
}

export const useGetAuctions = () => {
  return useQuery('auctions', () => getAuctions())
}

export const useGetAuction = (id) => {
  return useQuery(['auctions', id], () => getAuction(id))
}
