import React from 'react'
import Icon from './Icon'

const TailoredIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M34.694 11.995l-4.46-1.621V8.437a.703.703 0 00-.441-.652L26.61 6.512l-.616-2.464a.703.703 0 00-.682-.532h-.703v-2.11a.703.703 0 00-.703-.703h-2.813a.703.703 0 00-.703.703v2.11h-.703a.703.703 0 00-.682.532l-.616 2.464-3.182 1.273a.703.703 0 00-.442.652v1.937l-4.46 1.621a.703.703 0 00-.439.84L13.3 25.877c-.703 1.082-2.752 5.078-2.752 14.904a.703.703 0 00.703.703h8.438v2.11a.703.703 0 00.703.703h4.219a.703.703 0 00.703-.703v-2.11h8.437a.703.703 0 00.703-.703c0-9.826-2.048-13.822-2.752-14.904l3.432-13.042a.703.703 0 00-.44-.84zm-5.866-3.081v.227a.703.703 0 000 1.406v1.406a.703.703 0 000 1.406v1.407a.703.703 0 000 1.406v1.406a.703.703 0 000 1.406v1.407a.703.703 0 000 1.406v1.406a.703.703 0 000 1.406v1.407a.703.703 0 000 1.406v1.406a.703.703 0 000 1.406v1.407h-1.406V9.844a.705.705 0 00-.02-.17l-.37-1.48 1.796.72zm-7.03-6.805h1.405v1.407h-1.406V2.109zm-5.626 32.344h1.406v1.406h-1.406v-1.406zm0-25.54l1.797-.718-.37 1.478a.705.705 0 00-.02.17v23.204h-1.407v-2.813a.703.703 0 000-1.406v-1.406a.703.703 0 000-1.406v-1.407a.703.703 0 000-1.406v-1.406a.703.703 0 000-1.406v-1.407a.703.703 0 000-1.406v-1.406a.703.703 0 000-1.406v-1.407a.703.703 0 000-1.406v-1.406a.703.703 0 000-1.406v-.227zm7.735 33.978h-2.813v-1.407h2.813v1.407zm9.137-2.813H11.957c.109-10.408 2.618-13.579 2.64-13.605a.703.703 0 00.146-.636l-3.353-12.74 3.376-1.227v24.693a.703.703 0 00.703.703h2.813a.703.703 0 00.703-.703V9.93l1.252-5.008h4.527l1.252 5.008v22.414a.703.703 0 00.703.703h2.813a.703.703 0 00.703-.703v-6.322c0 .164.058.322.164.446.026.031 2.536 3.202 2.645 13.61zm-2.787-14.241a.726.726 0 00-.023.16V11.87l3.376 1.228-3.353 12.739z"
      fill="currentColor"
    />
  </Icon>
)

export default TailoredIcon
