import React from 'react'
import Icon from './Icon'

const LinkedInIcon = (props) => (
  <Icon viewBox="2 2 28 28" width="1em" height="1em" {...props}>
    <path
      d="M4.745 11.997H9.5v15.27H4.745zm2.374-7.6A2.752 2.752 0 0 1 7.12 9.9a2.76 2.76 0 0 1-2.754-2.753 2.75 2.75 0 0 1 2.753-2.75m5.35 7.6h4.552v2.087h.063c.634-1.2 2.182-2.466 4.5-2.466 4.806 0 5.693 3.163 5.693 7.274v8.376h-4.743V19.84c0-1.77-.032-4.05-2.466-4.05-2.47 0-2.85 1.93-2.85 3.92v7.554h-4.742v-15.27z"
      fill="currentColor"
    />
  </Icon>
)

export default LinkedInIcon
