import { useHistory } from 'react-router-dom'

export default function ScrollToTop() {
  let history = useHistory()

  history.listen((_) => {
    window.scrollTo(0, 0)
  })

  return null
}
