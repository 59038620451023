import React from 'react'
import classNames from 'classnames'

const LoadingSpinner = ({ className, page }) => {
  return (
    <div className={classNames('LoadingSpinner loading-spinner-container', className, { 'full-page': page })}>
      <div className="loading-spinner spin" />
    </div>
  )
}

export default LoadingSpinner

LoadingSpinner.defaultProps = {
  page: false,
}
