import { useQuery, useMutation, useQueryClient } from 'react-query'
import { protectedRequest, request } from './api'

const login = async (values) => {
  return await request('POST', 'auth/local', values).then((res) => res.data)
}

export const useLogin = () => {
  const queryClient = useQueryClient()

  return useMutation(({ values }) => login(values), {
    onSuccess: (data, { onSuccess }) => {
      queryClient.setQueryData('auth', data.user)
      onSuccess(data)
    },
    onError: (data, { onError }) => {
      onError(data)
    },
    throwOnError: true,
  })
}

const getMe = async () => {
  return await protectedRequest('GET', 'users/me').then((res) => res.data)
}

export const useAuth = () => {
  return useQuery('auth', getMe, {
    retry: 0,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    // throwOnError: true,
  })
}
