import React from 'react'
import Icon from './Icon'

const TradingIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M40.731 6.285h-8.055V3.64a.659.659 0 10-1.318 0v2.645h-2.729a.659.659 0 100 1.317h2.729v2.79h-.494c-.69 0-1.253.562-1.253 1.252V19.4c0 .69.562 1.252 1.253 1.252h.494v5.227a.659.659 0 001.318 0v-5.227h.494c.69 0 1.252-.562 1.252-1.252v-7.756c0-.69-.561-1.252-1.252-1.252h-.494v-2.79h8.055a2.874 2.874 0 012.871 2.871v22.55H1.4v-22.55a2.874 2.874 0 012.87-2.87h11.55v1.285h-.495c-.69 0-1.252.562-1.252 1.252v7.756c0 .69.562 1.252 1.252 1.252h.495v5.822a.659.659 0 001.317 0v-5.822h.495c.69 0 1.252-.561 1.252-1.252V10.14c0-.69-.562-1.252-1.252-1.252h-.495V7.602h2.73a.659.659 0 000-1.317h-2.73v-.326a.659.659 0 00-1.317 0v.326H4.269a4.193 4.193 0 00-4.188 4.188v23.208A4.193 4.193 0 004.27 37.87h10.029v3.35l-2.124 2.462h-.843a.659.659 0 000 1.318h22.276a.659.659 0 000-1.318h-.782l-2.124-2.463v-3.35h10.03a4.193 4.193 0 004.188-4.188V10.473c0-2.31-1.88-4.188-4.189-4.188zm-7.626 13.05h-2.176V11.71h2.176v7.625zm-15.54-1.504H15.39v-7.625h2.175v7.625zm23.166 18.721H27.398a.659.659 0 100 1.318h1.987v2.935h-1.641a.659.659 0 100 1.318h1.997l1.345 1.56H13.915l1.344-1.56h1.998a.659.659 0 100-1.318h-1.64V37.87h8.973a.659.659 0 100-1.318H4.27a2.875 2.875 0 01-2.794-2.212h42.05a2.875 2.875 0 01-2.795 2.212z"
      fill="currentColor"
    />
    <path
      d="M6.961 19.235a.659.659 0 00-.659.658v1.59c0 .691.562 1.253 1.253 1.253h.494v3.508a.659.659 0 101.318 0v-3.508h.494c.69 0 1.252-.562 1.252-1.252v-7.756c0-.69-.562-1.252-1.252-1.252h-.494v-1.054a.659.659 0 00-1.318 0v1.054h-.494c-.69 0-1.253.561-1.253 1.252v3.358a.659.659 0 001.318 0v-3.293h2.176v7.625H7.62v-1.524a.659.659 0 00-.659-.66zM23.093 17.845h.494v3.508a.659.659 0 101.318 0v-3.509h.495c.69 0 1.252-.561 1.252-1.252v-5.167a.659.659 0 10-1.318 0v5.102H23.16V4.597h2.175v4.02a.659.659 0 101.318 0V4.532c0-.69-.562-1.252-1.252-1.252h-.495V.659a.659.659 0 10-1.318 0v2.62h-.494c-.69 0-1.252.563-1.252 1.253v12.06c0 .69.561 1.253 1.252 1.253zM35.819 18.062a.659.659 0 00.893.267l3.034-1.636-.218.573a.659.659 0 101.231.469l.742-1.947a.659.659 0 00-.381-.85l-2.067-.788a.659.659 0 00-.469 1.232l.48.182-2.978 1.605a.659.659 0 00-.267.893zM3.978 30.935a.659.659 0 00.864.346l8.502-3.638.772 2.024a.66.66 0 00.928.345l14.045-7.573a.659.659 0 00-.625-1.16L15.08 28.496l-.75-1.964a.658.658 0 00-.874-.37L4.324 30.07a.66.66 0 00-.346.865z"
      fill="currentColor"
    />
  </Icon>
)

export default TradingIcon
