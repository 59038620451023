import React from 'react'
import { Link } from 'react-router-dom'
import { AnimatedItem } from '../app/'
import { ArrowIcon } from '../icons'

const WhatDoWeOfferItem = ({ title, description, icon: Icon, to, label }) => {
  return (
    <AnimatedItem className="WhatDoWeOfferItem column">
      <div className="icon-wrapper">{Icon && <Icon />}</div>
      <h4 className="title text-bold text-center">{title}</h4>
      <p className="description s">{description}</p>
      <Link className="link secondary text-center row xy-center" to={to}>
        {label} <ArrowIcon />
      </Link>
    </AnimatedItem>
  )
}

export default WhatDoWeOfferItem
