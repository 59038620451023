import React from 'react'
import { Link } from 'react-router-dom'
import { Section } from '../ui'
import { AnimateOnReveal } from '../app'

const item = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
}

const CallToAction = ({ text, buttonText }) => {
  return (
    <Section className="CallToAction l light py-0 mb-0" contentClassName="row space-between y-center mobile-column">
      <AnimateOnReveal duration={1} hidden={{ opacity: 0 }} visible={{ opacity: 1 }}>
        <h3
          className="text text-primary text-extra-bold mr-16"
          initial="hidden"
          animate="visible"
          variants={item}
          style={{ maxWidth: 600 }}
        >
          {text}
        </h3>
      </AnimateOnReveal>
      <AnimateOnReveal hidden={{ scale: 0.5 }} visible={{ scale: 1 }} duration={0.75}>
        <Link to="/contact" className="button l">
          {buttonText}
        </Link>
      </AnimateOnReveal>
    </Section>
  )
}

export default CallToAction
