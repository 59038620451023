import React from 'react'
import Icon from './Icon'

const AuctionIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M25.313 39.37v3.843H2.25v-3.844h23.063zm1.126-37.12l14.948 14.948-2.718 2.722-2.037-.68-4.76 4.754 10.872 10.872-2.718 2.718-10.87-10.872-4.62 4.62.544 2.175-2.72 2.718L7.412 21.277l2.72-2.718 2.171.542L24.4 7.007l-.679-2.038L26.44 2.25zm1.358 6.796l-13.59 13.587 6.795 6.796 13.59-13.587-6.794-6.796z"
      fill="currentColor"
    />
  </Icon>
)

export default AuctionIcon
