import React from 'react'
import Icon from './Icon'

const GasIcon = (props) => (
  <Icon viewBox="0 0 59 54" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.207 38.228c.537 0 .973.436.973.974v6.75a.974.974 0 0 1-1.947 0v-6.75c0-.538.436-.974.974-.974zM9.216 40.176a.844.844 0 0 0-.844.843v5.712a.974.974 0 1 1-1.947 0v-5.712a2.79 2.79 0 0 1 2.791-2.79h10.125a.974.974 0 0 1 0 1.947H9.216zM49.197 40.176c.466 0 .844.377.844.843v5.712a.974.974 0 1 0 1.947 0v-5.712a2.79 2.79 0 0 0-2.791-2.79H39.072a.974.974 0 0 0 0 1.947h10.125z"
      fill="#000"
    />
    <path d="M1.428 47.25h55.038v5.452H1.429V47.25z" fill="#E8F4FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.635 48.159a1.817 1.817 0 0 0 0 3.634h20.52a.909.909 0 0 1 0 1.818H3.634a3.635 3.635 0 1 1 0-7.27h50.884a3.635 3.635 0 1 1 0 7.27H33.453a.909.909 0 1 1 0-1.818h21.066a1.817 1.817 0 1 0 0-3.634H3.635z"
      fill="#000"
    />
    <path d="M30.245 52.832a1.168 1.168 0 1 1-2.337 0 1.168 1.168 0 0 1 2.337 0z" fill="#000" />
    <path
      d="M41.668 23.465c0 6.254-5.627 11.323-12.57 11.323-6.941 0-11.833-6.856-11.833-13.11 0-1.313.95-4.476 1.384-7.217.724-4.574.247-7.383-.606-9.004 1.288-.963 5.668 2.193 8.178 4.408C27.957 11.397 28.493 0 34.096 0c.353.873-.58 4.594 0 6.412 1.414 4.43 3.853 8.608 5.452 10.758 1.34 1.8 2.12 3.965 2.12 6.295z"
      fill="#FEB491"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.043 5.457c.253.48.472 1.065.638 1.769.392 1.672.477 4.016-.032 7.235-.192 1.212-.485 2.507-.752 3.687-.337 1.49-.632 2.797-.632 3.53 0 6.254 4.892 13.11 11.834 13.11 6.942 0 12.57-5.07 12.57-11.323 0-2.33-.782-4.495-2.12-6.295-1.6-2.15-4.039-6.328-5.453-10.758-.33-1.032-.17-2.68-.037-4.07.102-1.056.19-1.965.037-2.342-3.418 0-4.95 4.242-6.02 7.202-.683 1.892-1.178 3.26-1.855 2.663-1.646-1.452-4.096-3.309-5.95-4.144-.83-.374-1.541-.543-2.007-.38a.814.814 0 0 0-.22.116zm2.787 2.779c1.398.862 2.95 2.072 4.103 3.09.334.294.873.623 1.589.628a2.39 2.39 0 0 0 1.577-.597c.554-.478.886-1.17 1.074-1.591.226-.503.446-1.105.656-1.686l.079-.218c.194-.536.391-1.083.611-1.639.45-1.137.936-2.159 1.5-2.932a19.55 19.55 0 0 0-.065 1.474c0 .688.051 1.498.287 2.239 1.489 4.664 4.037 9.031 5.745 11.328 1.107 1.488 1.735 3.25 1.735 5.133 0 4.992-4.56 9.376-10.622 9.376-2.786 0-5.231-1.368-7.028-3.513-1.812-2.162-2.86-5.002-2.86-7.65 0-.387.17-1.252.483-2.646l.102-.452c.266-1.173.574-2.534.776-3.815.411-2.596.47-4.755.258-6.53zM32.225.495z"
      fill="#000"
    />
    <path
      d="M36.606 28.336c0 3.563-2.982 6.452-7.14 6.452-4.158 0-7.529-2.889-7.529-6.452 0-1.195.497-3.36 2.152-5.268 1.219-1.406 3.548-2.553 4.302-3.687 1.274-1.917-.661-2.766 1.075-2.766 1.9 0 3.826 3.132 5.063 5.322 1.469 2.602 2.077 4.464 2.077 6.399z"
      fill="#FECAB2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.466 32.841c3.233 0 5.193-2.161 5.193-4.505 0-1.463-.438-2.983-1.826-5.441-.614-1.088-1.333-2.287-2.087-3.2a9.085 9.085 0 0 0-.211-.246 5.01 5.01 0 0 1-.523 1.01c-.341.513-.794.939-1.186 1.268-.404.34-.858.672-1.265.969-.889.648-1.568 1.148-2.001 1.647-1.321 1.524-1.675 3.224-1.675 3.993 0 2.217 2.206 4.505 5.581 4.505zm1.178-15.86c-.39-.232-.784-.366-1.178-.366-.869 0-.818.213-.71.666.107.452.271 1.142-.365 2.1-.388.584-1.195 1.172-2.052 1.796-.807.588-1.66 1.21-2.25 1.891-1.655 1.908-2.151 4.073-2.151 5.268 0 3.563 3.37 6.452 7.528 6.452 4.158 0 7.14-2.889 7.14-6.452 0-1.935-.608-3.796-2.077-6.399-.97-1.718-2.364-4.015-3.837-4.926a3.103 3.103 0 0 0-.048-.03z"
      fill="#000"
    />
  </Icon>
)

export default GasIcon
