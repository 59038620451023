import axios from 'axios'
// import { API_URL } from '../../config'

const API_URL = process.env.REACT_APP_API_URL || '//localhost:1337'

export const request = (method, path, data) => {
  const headers = {
    Accept: 'application/json',
  }
  return axios.request({
    headers: headers,
    url: `${API_URL}/${path}`,
    method: method,
    responseType: 'json',
    contentType: 'application/json',
    data,
  })
}

export const protectedRequest = (method, path, data, responseType = 'json') => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  return axios.request({
    headers: headers,
    url: `${API_URL}/${path}`,
    method: method,
    responseType,
    data: data,
    contentType: 'application/json',
  })
}
