import React from 'react'
import Icon from './Icon'

const ContractRequirementsIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M9.07 1.957h27.46c.755 0 1.367.613 1.367 1.368v38.35c0 .755-.612 1.368-1.367 1.368H19.016c-.352 0-.691-.137-.946-.38L8.123 33.13a1.367 1.367 0 01-.421-.987V3.325c0-.755.612-1.368 1.367-1.368z"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
    />
    <path d="M20.068 31.5h-9.943l9.943 9.47V31.5z" fill="#fff" />
    <path
      d="M10.49 6.402a.71.71 0 01.71-.71h23.2a.71.71 0 010 1.42H11.2a.71.71 0 01-.71-.71zM10.49 15.871a.71.71 0 01.71-.71h23.2a.71.71 0 110 1.42H11.2a.71.71 0 01-.71-.71zM10.49 20.606a.71.71 0 01.71-.71h23.2a.71.71 0 110 1.42H11.2a.71.71 0 01-.71-.71zM10.49 24.867a.71.71 0 01.71-.71h23.2a.71.71 0 110 1.42H11.2a.71.71 0 01-.71-.71zM10.49 11.137a.71.71 0 01.71-.71h4.261a.71.71 0 010 1.42H11.2a.71.71 0 01-.71-.71zM18.539 11.137a.71.71 0 01.71-.71h14.677a.71.71 0 110 1.42H19.25a.71.71 0 01-.71-.71z"
      fill="#99ABD5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.59 34.573c.188.001.358.114.433.287l2.007 4.685h5.843a.474.474 0 010 .947h-6.155a.474.474 0 01-.435-.287l-1.7-3.967-1.219 2.786a.474.474 0 01-.434.284h-2.604a.474.474 0 010-.947h2.295l1.533-3.504a.473.473 0 01.435-.284z"
      fill="currentColor"
    />
  </Icon>
)

export default ContractRequirementsIcon
