import React from 'react'
import Icon from './Icon'

const EmissionsIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <g clipPath="url(#c_svg__clip0)">
      <path
        d="M7.73 31.6h8.042c.645.202.645 1.115 0 1.318H7.73a7.695 7.695 0 01-5.477-2.27 7.691 7.691 0 01-2.269-5.476A7.68 7.68 0 011.53 20.53a7.808 7.808 0 013.917-2.76.241.241 0 00.163-.237c0-2.526.897-4.969 2.525-6.887a.66.66 0 011.005.854 9.32 9.32 0 00-2.211 6.026c0 .7-.44 1.302-1.094 1.503a6.48 6.48 0 00-3.25 2.292 6.372 6.372 0 00-1.281 3.851c0 1.717.668 3.331 1.883 4.545A6.385 6.385 0 007.73 31.6zM32.807 27.784a.66.66 0 01.008 1.318c-.722.009-1.25.013-1.64.013-1.009 0-1.087-.026-1.173-.055a.682.682 0 01-.443-.45c-.11-.36.053-.574.38-1.002.258-.338.739-.967 1.545-2.12.222-.316.371-.609.432-.847.096-.37-.239-.722-.629-.722a.645.645 0 00-.632.519.66.66 0 01-1.294-.254c.18-.918.99-1.583 1.926-1.583 1.182 0 2.2 1.107 1.92 2.309-.095.412-.312.861-.643 1.335-.475.679-.84 1.18-1.114 1.55.416-.002.895-.006 1.348-.011h.009z"
        fill="currentColor"
      />
      <path
        d="M42.715 19.695a7.691 7.691 0 012.27 5.477c0 4.271-3.475 7.746-7.746 7.746h-4.714a.66.66 0 010-1.318h4.714a6.435 6.435 0 006.427-6.428 6.382 6.382 0 00-1.883-4.544 6.385 6.385 0 00-4.544-1.883c-.738 0-1.471-.388-1.699-1.059a7.922 7.922 0 00-7.505-5.373c-.84 0-1.666.13-2.457.388a1.55 1.55 0 01-1.745-.58 9.342 9.342 0 00-7.589-3.912 9.29 9.29 0 00-4.946 1.42.66.66 0 01-.7-1.117 10.607 10.607 0 015.646-1.621c3.43 0 6.667 1.668 8.661 4.463a.236.236 0 00.264.094 9.232 9.232 0 012.866-.454 9.24 9.24 0 018.753 6.267c.064.189.288.166.45.166 2.07 0 4.014.805 5.477 2.268zM17.51 19.381a3.011 3.011 0 00-4.688 2.494 3.01 3.01 0 003.006 3.008c.748 0 1.36-.268 1.805-.8a.659.659 0 111.01.848c-.693.826-1.682 1.27-2.815 1.27a4.33 4.33 0 01-4.325-4.325 4.33 4.33 0 014.325-4.326c.867 0 1.704.255 2.42.739a.66.66 0 11-.739 1.092z"
        fill="currentColor"
      />
      <path
        d="M24.017 17.55a4.33 4.33 0 014.326 4.325 4.33 4.33 0 01-4.326 4.326 4.33 4.33 0 01-4.326-4.326 4.33 4.33 0 014.326-4.325z"
        fill="currentColor"
      />
      <path d="M27.133 21.857a2.978 2.978 0 11-5.956 0 2.978 2.978 0 015.956 0z" fill="#fff" />
      <path
        d="M17.514 41.383a.5.5 0 00.707 0l3.182-3.182a.5.5 0 10-.707-.707l-2.828 2.828-2.829-2.828a.5.5 0 10-.707.707l3.182 3.182zm-.146-10.942V41.03h1V30.441h-1zM27.44 41.383a.5.5 0 00.708 0l3.182-3.182a.5.5 0 00-.707-.707l-2.829 2.828-2.828-2.828a.5.5 0 10-.707.707l3.182 3.182zm-.146-10.942V41.03h1V30.441h-1z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="c_svg__clip0">
        <path fill="#fff" d="M0 0h45v45H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default EmissionsIcon
