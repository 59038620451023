import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import JotformEmbed from 'react-jotform-embed'
// import GetSheetDone from 'get-sheet-done'
import { useQueryParam, BooleanParam } from 'use-query-params'
import cns from 'classnames'
import moment from 'moment'

import { BackArrowIcon, DownloadIcon, EnergyIcon, GasIcon } from '../../components/icons'
import { LoadingSpinner, Section, View } from '../../components/ui'
import { useGetAuction } from '../../utils/useAuction'
import { useAuth } from '../../utils/useAuth'
import { TimeLeft, ResultTable, ThankYouModal } from '../../components/auction'

import useGoogleSheets from 'use-google-sheets'
import { isEmpty } from 'lodash'

// const fetchData = async (id, sheetNum) => {
//   const result = await GetSheetDone.labeledCols(id, sheetNum)
//   const columns = await GetSheetDone.raw(id, sheetNum)

//   let year = 0

//   const formattedData = result.data.reduce((years, curr) => {
//     if (curr?.rank === 'Rank') {
//       year++
//       return years
//     } else {
//       const currentYear = years[year] || []
//       return {
//         ...years,
//         [year]: [...currentYear, curr],
//       }
//     }
//   }, {})

//   console.log('oldformattedData', formattedData)

//   const formattedColumns = columns.data[0]
//     .filter((col) => col !== 'Rank' && col !== 'Supplier')
//     .reduce((cols, curr) => {
//       return [
//         ...cols,
//         {
//           Header: curr,
//           accessor: curr
//             .toLowerCase()
//             .replace(/\s/g, '')
//             .replace(/[^a-zA-Z ]/g, ''),
//         },
//       ]
//     }, [])

//   return await { columns: formattedColumns, data: formattedData }
// }

const formatNewData = (newData, sheetNum) => {
  console.log(newData)

  const result = newData[sheetNum - 1]

  let year = 0

  const formattedData = result.data.reduce((years, curr) => {
    const formattedObj = Object.keys(curr).reduce((accum, currKey) => {
      const formattedKey = currKey
        .toLowerCase()
        .replace(/\s/g, '')
        .replace(/[^a-zA-Z ]/g, '')
      return {
        ...accum,
        [formattedKey]: curr[currKey],
      }
    }, {})

    if (curr?.Rank === 'Rank') {
      year++
      return years
    } else {
      const currentYear = years[year] || []
      return {
        ...years,
        [year]: [...currentYear, formattedObj],
      }
    }
  }, {})

  console.log('newformattedData', formattedData)

  const formattedColumns = Object.keys(result.data[0])
    .filter((col) => col !== 'Rank' && col !== 'Supplier')
    .reduce((cols, curr) => {
      return [
        ...cols,
        {
          Header: curr,
          accessor: curr
            .toLowerCase()
            .replace(/\s/g, '')
            .replace(/[^a-zA-Z ]/g, ''),
        },
      ]
    }, [])
  return { columns: formattedColumns, data: formattedData }
}

const Auction = ({ match }) => {
  const query = useGetAuction(match.params.id)
  const { data: auction } = query

  const history = useHistory()

  // const [data, setData] = useState()
  // const [columns, setColumns] = useState()
  // const [isLoadingResults, setIsLoadingResults] = useState(true)

  const [hasJustBid, setHasJustBid] = useQueryParam('thankYou', BooleanParam)
  const [isOpen, setIsOpen] = useState(hasJustBid)
  const [showResults, setShowResults] = useState()

  const { data: user } = useAuth()

  const supplier = auction?.supplier?.find((s) => s?.users_permissions_user?.id === user?.id)

  const isAdmin = user?.role?.name === 'Admin'

  const resultsSheet = auction?.resultsSheet

  let sheetToUse = {
    raw: resultsSheet?.rawClientSheetNum,
    downloadable: resultsSheet?.downloadableClientSheetNum,
  }

  if (supplier) {
    sheetToUse = {
      raw: resultsSheet?.rawSupplierSheetNum,
      downloadable: resultsSheet?.downloadableSupplierSheetNum,
    }
  }

  const isInProgress = moment().isBetween(auction?.start, auction?.end)
  const hasEnded = moment().isAfter(auction?.end)

  const {
    data: pulledData,
    loading: isLoadingResults,
    refetch,
  } = useGoogleSheets({
    apiKey: 'AIzaSyDRvnjTJdua5ItEwQzmF4MxHQfESepe_ls',
    sheetId: resultsSheet?.sheetId,
  })

  let columns = []
  let data

  if (!isLoadingResults && !isEmpty(pulledData)) {
    let { columns: formattedColumns, data: formattedData } = formatNewData(pulledData, sheetToUse?.raw)
    columns = formattedColumns
    data = formattedData
  }

  // useEffect(() => {
  //   if (resultsSheet?.sheetId && sheetToUse?.raw) {
  //     setTimeout(
  //       () => {
  //         let { columns: formattedColumnsNew, data: formattedDataNew } = formatNewData(pulledData, sheetToUse?.raw)
  //         columns = formattedColumnsNew
  //         data = formattedDataNew
  //       },
  //       hasJustBid ? 3000 : 0
  //     )
  //   }
  // }, [hasJustBid, resultsSheet?.sheetId, sheetToUse?.raw])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasJustBid])

  useEffect(() => {
    const supplierYear1 = !isEmpty(data) && data[0].find((bid) => bid?.supplier === supplier?.resultsName)
    if (supplier) {
      setShowResults(supplier && supplierYear1 && supplierYear1?.rank !== '')
    } else {
      setShowResults(true)
    }
  }, [data, supplier, hasJustBid])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View className="pb-48 Auction">
      <Section className="grey" contentClassName="col">
        <div className="row space-between y-center pb-48 mb-40" style={{ borderBottom: '2px solid #E5E5E5' }}>
          <div className="flex-1 row y-center">
            <div className="mr-16 cursor-pointer" onClick={() => history.push('/auctions')}>
              <BackArrowIcon size={40} />
            </div>
            {auction?.type === 'Electricity' ? <EnergyIcon size={56} /> : <GasIcon size={56} />}
            <h2 className="title ml-24">{auction?.type} Auction</h2>
          </div>
          <div>
            <TimeLeft auction={auction} />
          </div>
        </div>
        <div className="row space-between mb-64">
          <div className="flex-2">
            <h6 className="subtitle">CLIENT NAME</h6>
            <h3 className="text-primary mt-12" style={{ fontSize: 28, fontWeight: 600 }}>
              {auction?.name}
            </h3>
          </div>
          <div className="flex-3 px-24">
            <h6 className="subtitle">CLIENT ADDRESS</h6>
            <h3 className="text-primary mt-12" style={{ fontSize: 28, fontWeight: 600 }}>
              {auction?.address}
            </h3>
          </div>
          <div className="flex-1">
            <h6 className="subtitle text-right">REGISTERED NUMBER</h6>
            <h3 className="text-primary mt-12 text-right" style={{ fontSize: 28, fontWeight: 600 }}>
              {auction?.registeredNumber}
            </h3>
          </div>
        </div>
        <div className="row space-between">
          <div className="flex-2">
            <h6 className="subtitle">ATTACHED FILES</h6>
            {auction?.files?.length ? (
              <div className="column">
                {auction?.files?.map((file, index) => (
                  <div key={index} className="row y-center mt-12">
                    <h5 className="text-primary text-bold" style={{ minWidth: 150 }}>
                      {file.name}
                    </h5>
                    <a
                      href={file.url}
                      className="ml-8 link s text-thin row y-center text-nowrap"
                      rel="noreferrer"
                      target="_blank"
                      download
                    >
                      <DownloadIcon size="14" /> <span className="ml-4">View File</span>
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-default mt-12">No Attached Files</p>
            )}
          </div>
          <div className="flex-3 px-24">
            <h6 className="subtitle">DETAILS</h6>
            <p className="text-primary s mt-12">{auction?.details}</p>
          </div>

          <div className="flex-1">
            <h6 className="subtitle text-right">CONTRACT TYPE</h6>
            <p className="text-primary mt-12 text-bold text-right">{auction?.fixed ? 'Fixed' : 'Flexible'}</p>
          </div>
        </div>
      </Section>
      {!isLoadingResults ? (
        <>
          <Section className="pb-12">
            <details open>
              <summary className="mb-24 text-primary">
                <div className="summary-wrapper row y-center">
                  <h2 className="flex-1 ml-16 text-primary">Auction Results {isAdmin && '(Client)'}</h2>
                  {isAdmin && (
                    <a
                      className="link text-uppercase secondary l row y-top"
                      href={`https://docs.google.com/spreadsheets/d/${resultsSheet?.sheetId}/edit?format=pdf&gid=${sheetToUse?.downloadable}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="ml-8">View Full Results Sheet</span>
                    </a>
                  )}
                  {showResults && !isAdmin && (
                    <a
                      className="link text-uppercase secondary l row y-top"
                      href={`https://docs.google.com/spreadsheets/d/${resultsSheet?.sheetId}/export?format=pdf&gid=${sheetToUse?.downloadable}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DownloadIcon width="22" /> <span className="ml-8">Download PDF</span>
                    </a>
                  )}
                </div>
              </summary>
              {!showResults && (
                <div className="submit-first-message">
                  <h4 className="text-primary">
                    {isInProgress
                      ? 'Submit an offer to View Results'
                      : hasEnded
                      ? 'Bidding is now closed'
                      : 'Bidding is not open yet'}
                  </h4>
                </div>
              )}
              <div className={cns({ hide: !showResults })}>
                {!isEmpty(data) &&
                  Object?.keys(data)?.map((year, index) => (
                    <ResultTable
                      key={index}
                      className="mb-32"
                      title={`${index + 1} Year Contract`}
                      data={data[year]}
                      columns={columns}
                      supplierName={supplier?.resultsName}
                    />
                  ))}
              </div>
            </details>
          </Section>
          {supplier && isInProgress && (
            <Section className="pt-0" id="offer">
              <details open>
                <summary>
                  <div className="summary-wrapper row y-center ">
                    <h2 className="ml-16 text-primary">Place Offer</h2>
                  </div>
                </summary>
                {supplier?.bidForm && <JotformEmbed src={supplier.bidForm} scrolling />}
              </details>
            </Section>
          )}
        </>
      ) : (
        <LoadingSpinner className="small-page" />
      )}
      <ThankYouModal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
          setHasJustBid(undefined, 'replace')
          setShowResults(true)
        }}
      />
    </View>
  )
}

export default Auction
