import React from 'react'
import { LinkedInIcon, LogoAndTextColIcon, LogoAndTextIcon, TwitterIcon } from '../icons'
import { withCMS } from './WithCMS'

const Footer = ({ data }) => {
  var date = new Date()
  var year = date.getFullYear()

  return (
    <footer className="Footer">
      <div className="content footer-content">
        <div className="logo">
          <LogoAndTextIcon />
        </div>
        <div className="mobile-logo">
          <LogoAndTextColIcon />
        </div>
        <div className="social column x-center">
          <h6 className="text-thin">{data?.socialMediaTitle}</h6>
          <div className="social-links row mt-8">
            <a
              className="social-link row xy-center linked-in"
              href={data?.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              className="social-link row xy-center twitter"
              href={data?.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
        <div className="contact column x-right">
          <a className="link text-thin light pb-8" href={`mailto:${data?.email}`}>
            {data?.email}
          </a>
          <a className="link text-thin light" href={`tel:${data?.phone}`}>
            {data?.phone}
          </a>
        </div>
        <div className="legal row x-center">
          <small className="text-thin text-center">
            Copyright {year} - {data?.copyright}
          </small>
        </div>
      </div>
    </footer>
  )
}

export default withCMS(Footer)
