import React from 'react'
import Icon from './Icon'

const BackArrowIcon = (props) => (
  <Icon viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M31.667 20H8.333M20 31.667L8.334 20 20 8.334"
      stroke="#7D8290"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
)

export default BackArrowIcon
