import React, { useState, useEffect } from 'react'
import { isUndefined } from 'lodash'
import { LoadingSpinner } from '../ui'

// import GetSheetDone from 'get-sheet-done'
// import { camelCase, get, isUndefined } from 'lodash'
// import useGoogleSheets from 'use-google-sheets'

const DATA = [
  {
    english: {
      headerText1: 'Energy & carbon management',
      openingSubtitle: 'IRISH COMMODITIES',
      openingTitle: 'How we can help you?',
      openingDescription:
        'We ensure your energy strategy drives real value to your business, because we don’t just compare supplier prices. We obsessively watch wholesale energy markets for the cheapest time to buy your energy. Then we help reduce your business’s energy consumption, and if you want to go carbon neutral and boost your brand, we help you do that too.',
      uspTitle: 'What do we offer?',
      uspSubtitle: 'IRISH COMMODITIES',
      uspTitle1: 'Contract selection',
      uspDescription1:
        'After listening carefully to your requirements we’ll recommend the best fitting gas & power supplier offerings',
      uspTitle2: 'Energy procurement',
      uspDescription2:
        'We’ll use our market analysis to advise you when to buy. Then we’ll hold an energy auction where suppliers bid to supply you',
      uspTitle3: 'Demand management',
      uspDescription3: 'We’ll help you monitor, understand, and then reduce your energy consumption',
      uspTitle4: 'Demand response',
      uspDescription4:
        'For being available to reduce your energy consumption when requested, we’ll ensure regular payments',
      uspTitle5: 'Carbon footprint reduction',
      uspDescription5:
        'We’ll measure and help cut your emissions. Then you can publicise your green credentials with formal certification',
      uspButton: 'Learn more',
      callToActionText: 'Want to save time, \nmoney, energy, and \nemissions?\n',
      callToActionButton: 'Let’s talk',
    },
    irish: {
      headerText1: 'Bainistíocht fuinnimh agus carbóin',
      openingSubtitle: 'TRÁCHTEARRAÍ ÉIREANNEACHA',
      openingTitle: 'Conas is féidir linn cabhrú leat?',
      openingDescription:
        'Cinnteoimid go gcuirfidh do straitéis fuinnimh fíorluach ar fáil do do ghnó, mar déanaimid i bhfad níos mó ná comparáid a dhéanamh idir praghsanna soláthraithe. Bímid ag faire go géar ar mhargaí mórdhíola fuinnimh le fáil amach cad é an t-am is fearr duit chun fuinneamh a cheannach ar an ráta is ísle. Ansin cabhraímid leat chun an méid fuinnimh a úsáideann do ghnó a laghdú, agus más mian leat a bheith neodrach ó thaobh carbóin de agus do bhranda a threisiú, cuidímid leat é sin a dhéanamh freisin.',
      uspTitle: 'Cad atá á thairiscint againn?',
      uspSubtitle: 'TRÁCHTEARRAÍ ÉIREANNEACHA',
      uspTitle1: 'Roghnú conartha',
      uspDescription1:
        'Tar éis dúinn éisteacht go cúramach le do riachtanais molfaimid na tairiscintí ó sholáthraithe gáis agus cumhachta is fearr a fheileann duit',
      uspTitle2: 'Soláthar fuinnimh',
      uspDescription2:
        'Bainfimid leas as ár n-anailís mhargaidh chun comhairle a chur ort cathain is ceart duit ceannach a dhéanamh. Ansin beidh ceant fuinnimh againn nuair a dhéanfaidh soláthraithe tairiscint chun freastal ort',
      uspTitle3: 'Bainistiú éilimh',
      uspDescription3:
        'Cabhróimid leat monatóireacht a dhéanamh ar an méid fuinnimh a úsáideann tú, é a thuiscint agus ansin é a laghdú',
      uspTitle4: 'Freagra ar éileamh',
      uspDescription4:
        'Chun a bheith ar fáil chun an méid fuinnimh a úsáideann tú a laghdú nuair a iarrtar é sin, cinnteoimid íocaíochtaí rialta',
      uspTitle5: 'Do lorg carbóin a laghdú',
      uspDescription5:
        'Déanfaimid d’astaíochtaí a thomhas agus cabhróimid leat iad a laghdú. Ansin is féidir leat do cháilíochtaí glasa a phoibliú nuair a bheidh deimhniú foirmiúil agat',
      uspButton: '\nFoghlaim tuilleadh',
      callToActionText: 'An mian leat am, airgead agus fuinneamh a spáráil agus astaíochtaí a laghdú?',
      callToActionButton: 'Bímis ag caint',
    },
  },
  {
    english: {
      headerText: 'Energy procurement',
      title1: 'Irish & pan-European energy procurement',
      subtitle1: 'ENERGY PROCUREMENT',
      description1:
        'We procure gas and electricity in Ireland and across Europe’s deregulated energy markets. Through our premium market analysis and unique online energy auction we’ll transform the way you buy energy and save you time and money in the process.',
      title2: 'Market analysis & timing',
      subtitle2: 'ENERGY PROCUREMENT',
      description2:
        'Wholesale energy costs account for around 60% of a business energy bill according to the Commission for Regulation of Utilities (CRU). With wholesale energy costs fluctuating constantly on the open market, choosing the right time to buy is vital to getting the cheapest energy rate. Our experienced trading desk has access to pricing feeds from all major European energy markets, so you can rely on us to navigate the market volatility and help you pick the right time to buy. ',
      title3: 'Energy auction',
      subtitle3: 'ENERGY PROCUREMENT',
      description3:
        'In our online energy auction we invite prequalified gas and electricity suppliers to bid to supply your company based on their margin and delivery costs. The cheapest price wins the auction and the competition between suppliers ensures you get the best price possible. And if you like, you can logon to watch the bidding evolve. ',
      processTitle: 'Our process',
      processSubtitle: 'ENERGY PROCUREMENT',
      processDescription:
        'Dependent on how much gas or electricity your business uses, a supplier will typically offer you either a fixed or flexible supply contract. We’ll guide you through the process.',
      processOption1: 'FIXED CONTRACT',
      processOptionDescription1:
        'A fixed contract gives the user one opportunity to fix the energy rate for the contract period',
      processItemTitle1: 'Define contract requirements',
      processItemDescription1: 'We’ll make it our business to understand completely the energy needs of your business',
      processItemTitle2: 'Prequalify suppliers',
      processItemDescription2: 'We’ll identify the suppliers that best fit your energy requirements',
      processItemTitle3: 'Time market entry',
      processItemDescription3: 'We’re constantly monitoring energy markets to pick the right time for you to buy',
      processItemTitle4: 'Energy auction',
      processItemDescription4:
        'When we identify an opportunity to buy we’ll hold an online auction to fix both your energy rate and the supplier margin',
      processOption2: 'FLEXIBLE CONTRACT',
      processOptionDescription2:
        'A flexible contract is for larger energy users and allows multiple buys (and sells) for the contract period.',
      process2ItemTitle1: 'Define contract requirements',
      process2ItemDescription1:
        'After listening carefully to your energy needs we’ll draw up a questionnaire for potential suppliers ',
      process2ItemTitle2: 'Prequalify suppliers',
      process2ItemDescription2: 'We’ll identify the most suitable suppliers for you',
      process2ItemTitle3: 'Energy auction',
      process2ItemDescription3: 'We’ll hold an online auction to choose your supplier and fix their margin',
      process2ItemTitle4: 'Define trading & risk management strategy',
      process2ItemDescription4: 'Together we’ll regularly update your buying strategy and set price trigger levels ',
      process2ItemTitle5: 'Trading',
      process2ItemDescription5:
        'We’ll monitor energy markets and advise you on the best buying opportunities to gradually fix your energy rate',
      callToActionText: 'Want to maximise savings through market timing?',
      callToActionButton: 'Get started',
    },
    irish: {
      headerText: 'Soláthar fuinnimh',
      title1: 'Soláthar fuinnimh in Éirinn & ar fud na hEorpa',
      subtitle1: 'SOLÁTHAR FUINNIMH',
      description1:
        'Soláthraímid gás agus leictreachas in Éirinn agus ar fud mhargaí fuinnimh dírialáilte na hEorpa. Trínár n-anailís ar an margadh phréimhe agus ár gceant uathúil fuinnimh ar líne athróimid an bealach a cheannaíonn tú fuinneamh agus spárálfaimid am agus airgead ort freisin',
      title2: 'Anailís agus uainiú margaidh',
      subtitle2: 'SOLÁTHAR FUINNIMH',
      description2:
        'Is ionann costais mhórdhíola fuinnimh agus thart ar 60% de bhille fuinnimh gnólachtaí de réir an Choimisiúin um Rialú Fóntas (CRU). Ós rud é go mbíonn costais mhórdhíola fuinnimh ag athrú i gcónaí ar an margadh oscailte, tá sé ríthábhachtach an t-am ceart a roghnú chun an ráta fuinnimh is ísle a fháil. Tá an-taithí ag ár ndeasc thrádála agus rochtain acu ar fhothaí praghsála ó mhórmhargaí fuinnimh na hEorpa agus mar sin is féidir leat braith orainn gluaiseacht go réidh trí luaineacht an mhargaidh agus cabhrú leat an t-am ceart a roghnú le ceannach.',
      title3: 'Ceant fuinnimh',
      subtitle3: 'SOLÁTHAR FUINNIMH',
      description3:
        'Inár gceant fuinnimh ar líne iarraimid ar sholáthraithe réamhcháilithe fuinnimh agus leictreachais tairiscintí a dhéanamh chun soláthar a dhéanamh ar do chuideachta ar bhunús a gcorrlach agus gcostas seachadta. Bíonn an bua ag an bpraghas is saoire sa cheant agus cinntíonn an iomaíocht idir soláthraithe go bhfaighidh tú an praghas is fearr is féidir. Agus más maith leat, is féidir leat logáil isteach chun féachaint ar na tairiscintí ag teacht chun cinn. ',
      processTitle: 'Ár bpróiseas',
      processSubtitle: 'SOLÁTHAR FUINNIMH',
      processDescription:
        'Ag braith ar an méid gáis nó leictreachais a úsáideann do ghnólacht, is gnách go dtairgfidh soláthraí conradh soláthair seasta nó solúbtha duit. Déanfaimid thú a threorú tríd an bpróiseas.',
      processOption1: 'CONRADH SEASTA',
      processOptionDescription1:
        'Tugann conradh seasta deis amháin don úsáideoir an ráta fuinnimh a shocrú ar feadh thréimhse an chonartha',
      processItemTitle1: 'Sainmhínigh riachtanais chonartha',
      processItemDescription1: 'Féachaimid chuige go dtuigeann tú riachtanais fuinnimh do ghnólachta go hiomlán',
      processItemTitle2: 'Soláthraithe a réamhcháiliú',
      processItemDescription2: 'Aithneoimid na soláthraithe is fearr a oireann do na riachtanais fuinnimh atá agat.',
      processItemTitle3: 'Am le dul isteach sa mhargadh',
      processItemDescription3:
        'Bímid i gcónaí ag déanamh monatóireachta ar mhargaí fuinnimh chun an t-am ceart a roghnú duit le ceannach a dhéanamh',
      processItemTitle4: 'Ceant fuinnimh',
      processItemDescription4:
        'Nuair a aithnímid deis maidir le ceannach beidh ceant ar líne againn chun do ráta fuinnimh agus corrlach an tsoláthraí a shocrú',
      processOption2: 'CONRADH SOLÚBTHA',
      processOptionDescription2:
        'Tá conradh solúbtha ann dóibh siúd a úsáideann níos mó fuinnimh agus ceadaíonn sé ilcheannaigh (agus díolacháin) i rith thréimhse an chonartha.',
      process2ItemTitle1: 'Sainmhínigh riachtanais chonartha',
      process2ItemDescription1:
        'Tar éis éisteacht go cúramach le do riachtanais fuinnimh déanfaimid ceistneoir a dhréachtú do sholáthraithe ionchasacha',
      process2ItemTitle2: 'Soláthraithe a réamhcháiliú',
      process2ItemDescription2: 'Aithneoimid na soláthraithe is oiriúnaí duit',
      process2ItemTitle3: 'Ceant fuinnimh',
      process2ItemDescription3: 'Beidh ceant ar líne againn chun do sholáthraí a roghnú agus a gcorrlaigh a shocrú',
      process2ItemTitle4: 'Sainmhínigh an straitéis trádála & bainistíochta riosca',
      process2ItemDescription4:
        'Oibreoimid i gcomhar le chéile chun do straitéis cheannaigh a nuashonrú go rialta agus socróimid na leibhéil sin ar a spreagfaidh na praghsanna athrú',
      process2ItemTitle5: 'Trádáil',
      process2ItemDescription5:
        'Déanfaimid monatóireacht ar mhargaí fuinnimh agus tabharfaimid comhairle duit faoi na deiseanna ceannaigh is fearr chun do ráta fuinnimh a shocrú de réir a chéile',
      callToActionText: 'An bhfuil tú ag iarraidh coigilteas a uasmhéadú trí uainiú an mhargaidh?',
      callToActionButton: 'Tosaigh',
    },
  },
  {
    english: {
      headerText: 'Demand management',
      title1: 'Understand and reduce comsumption',
      subtitle1: 'DEMAND MANAGEMENT',
      description1:
        'How and when you use energy will impact your company’s energy spend. Our user-friendly energy management solution makes it easy to understand your energy use and make cost-saving energy reduction decisions. Studies by the Sustainable Energy Authority of Ireland (SEAI) suggest you can reasonably expect a 20% reduction in energy consumption from an effective energy management solution. So we are confident we can save you energy and money.',
      title2: 'Our energy management solution',
      subtitle2: 'DEMAND MANAGEMENT',
      description2:
        'Our solution converts data from sensors into easy to understand management information so you can clearly see the volume, location, and timing of your energy use. You can set energy usage targets and alerts which will help your business become more compliant with the ISO 50001 standard for energy management systems. And we’ll look at your energy data with you to identify more ways to reduce and better schedule your energy consumption.',
      processTitle: 'Our process',
      processSubtitle: 'DEMAND MANAGEMENT',
      processItemTitle1: 'Site survey',
      processItemDescription1: 'So we can understand the technical details of your energy using assets',
      processItemTitle2: 'Install monitoring devices',
      processItemDescription2: 'We’ll position sensors to track your energy data and feedback to our user software',
      processItemTitle3: 'Ongoing monitoring',
      processItemDescription3: 'We’ll help you understand and reduce your day-to-day energy usage',
      processItemTitle4: 'Recommended cost-savings projects',
      processItemDescription4:
        'Based on our experience we’ll  recommend cost saving projects with the best return on investment',
      callToActionText: 'Want to save money and cut your carbon footprint?',
      callToActionButton: "Let's talk",
    },
    irish: {
      headerText: 'Bainistiú éilimh',
      title1: 'Ídiú a thuiscint agus a laghdú',
      subtitle1: 'BAINISTIÚ ÉILIMH',
      description1:
        'Beidh tionchar ag an gcaoi agus an uair a úsáideann tú fuinneamh ar ar an méid a chaitheann do chuideachta ar fhuinneamh. Fágann ár réiteach um bainistiú fuinnimh atá furasta é a úsáid agus a thuiscint nach bhfuil sé deacair d’úsáid fuinnimh a thuiscint agus cinntí a dhéanamh maidir le laghdú fuinnimh chun costais a spáráil. Tugtar le fios i staidéir ó Údarás Fuinnimh Inmharthana na hÉireann (SEAI) gur féidir leat a bheith ag súil le réasún go dtiocfaidh laghdú 20% ar ídiú fuinnimh má ghlacann tú le réiteach éifeachtach um bainistiú fuinnimh. Mar sin táimid muiníneach go bhféadfaimid cabhrú leat fuinneamh agus airgead a spáráil.',
      title2: 'Ár réiteach maidir le bainistiú fuinnimh',
      subtitle2: 'BAINISTIÚ ÉILIMH',
      description2:
        'Mar chuid dár réiteach tiontaímid sonraí ó bhraiteoirí go faisnéis bhainistíochta atá furasta a thuiscint ionas gur féidir leat méid, suíomh agus uainiú d’úsáid fuinnimh a fheiceáil go soiléir. Féadfaidh tú spriocanna agus foláirimh maidir le húsáid fuinnimh a shocrú a chabhróidh le do ghnó cloí níos fearr le caighdeán ISO 50001 do chórais bhainistíochta fuinnimh. Agus féachfaimid ar do chuid sonraí fuinnimh in éineacht leat chun níos mó bealaí a aithint chun an méid fuinnimh a úsáideann tú a laghdú agus a sceidealú níos fearr.',
      processTitle: 'Ár bpróiseas',
      processSubtitle: 'BAINISTIÚ ÉILIMH',
      processItemTitle1: 'Suirbhé láithreáin',
      processItemDescription1: 'Chun go mbeimid in ann sonraí teicniúla do shócmhainní úsáidte fuinnimh a thuiscint',
      processItemTitle2: 'Feistí monatóireachta a shuiteáil',
      processItemDescription2:
        'Suífimid braiteoirí chun rianú a dhéanamh ar do shonraí fuinnimh agus aiseolas a sholáthar dár mbogearraí úsáideora',
      processItemTitle3: 'Monatóireacht leanúnach',
      processItemDescription3: 'Cabhróimid leat d’úsáid fuinnimh ó lá go lá a thuiscint agus a laghdú',
      processItemTitle4: 'Tionscadail mholta um choigilt costais',
      processItemDescription4:
        'Bunaithe ar ár dtaithí molfaimid tionscadail um choigilt costais a mbeidh an toradh is fearr acu ar infheistíocht',
      callToActionText: 'An dteastaíonn uait airgead a shabháil agus do lorg carbóin a laghdú?',
      callToActionButton: 'Bímis ag caint',
    },
  },
  {
    english: {
      headerText: 'Demand response',
      title1: 'Monetise demand flexibility',
      subtitle1: 'DEMAND RESPONSE',
      description1:
        'Once you can monitor and understand your energy usage, you can monetise flexibility in your demand by participating in demand response. Participants can receive substantial payments from Eirgrid for reducing consumption during times of peak electricity demand. Your business will be given one hour’s notice of any interruption and the event is kept to a maximum of two hours.',
      title2: 'How does it work?',
      subtitle2: 'DEMAND RESPONSE',
      description2:
        'The easiest ways to reduce demand when called upon include switching something off, delaying a process or switching to standby generation. You can also declare yourself unavailable at any time.\nWe’ll evaluate if it is technically and financially viable for your site to participate in the demand response scheme. If it is possible, your site will then be added to a Demand Side Unit composed of several energy users. Don’t worry, we’ll set up the system and run the scheme on your behalf and inform you when there is a demand event. \n',
      processTitle: 'Our process',
      processSubtitle: 'DEMAND RESPONSE',
      processItemTitle1: 'Site survey',
      processItemDescription1: 'To establish the suitability of your site to participate in the demand response scheme',
      processItemTitle2: 'Tailor a solution',
      processItemDescription2: 'We’ll look at the best way for your site to respond to a demand reduction request',
      processItemTitle3: 'Install monitoring & control technology',
      processItemDescription3:
        'Your site will be connected and enabled to contribute to balancing the grid during peak demand',
      processItemTitle4: 'Trading',
      processItemDescription4:
        'We’ll manage the scheme on your behalf and trade your demand reduction capacity in the energy market ',
      callToActionText: 'Want to reduce demand\nand get paid?\n',
      callToActionButton: 'Get in touch',
    },
    irish: {
      headerText: 'Freagra ar éileamh',
      title1: 'Luach airgid a shocrú do sholúbthacht éilimh',
      subtitle1: 'FREAGRA AR ÉILEAMH',
      description1:
        'Chomh luath agus is féidir leat monatóireacht a dhéanamh ar d’úsáid fuinnimh agus é a thuiscint, is féidir leat luach airgid a shocrú do sholúbthacht d’éileamh trí pháirt a ghlacadh i bhfreagairt éilimh. Féadfaidh rannpháirtithe íocaíochtaí móra a fháil ó Eirgrid as ídiú a laghdú le linn buaicéilimh leictreachais. Tabharfar fógra uair an chloig do do ghnólacht faoi aon chur isteach ar an soláthar agus ní ligtear don ghearradh leanúint ar aghaidh níos faide ná dhá uair an chloig ar a mhéad.',
      title2: 'Conas a oibríonn sé?',
      subtitle2: 'FREAGRA AR ÉILEAMH',
      description2:
        'Is iad na bealaí is éasca chun éileamh a laghdú nuair a iarrtar é sin ort ná rud éigin a mhúchadh, moill a chur ar phróiseas nó aistriú chuig gineadóir cúltaca. Féadfaidh tú a dhearbhú ag am ar bith nach bhfuil tú ar fáil.\nDéanfaimid meastóireacht le féachaint an bhfuil sé inmharthana ó thaobh na teicneolaíochta de agus ó thaobh airgid de go nglacfadh do shuíomh páirt sa scéim um fhreagra ar éileamh. Más féidir, cuirfear do shuíomh san Aonad um Fhreagairt don Éileamh ina bhfuil roinnt mhaith úsáideoirí fuinnimh. Ná bíodh imní ort, bunóimid an córas agus reáchtálfaidh muid an scéim ar do shon agus cuirfimid ar an eolas tú nuair a bhíonn éileamh ann. \n',
      processTitle: 'Ár bpróiseas',
      processSubtitle: 'FREAGRA AR ÉILEAMH',
      processItemTitle1: 'Suirbhé láithreáin',
      processItemDescription1:
        'A chruthú go bhfuil do shuíomh oiriúnacht chun páirt a ghlacadh sa scéim um fhreagairt ar éileamh',
      processItemTitle2: 'Réiteach a oiriúnú',
      processItemDescription2:
        'Féachfaimid ar an mbealach is fearr a thabharfaidh do shuíomh freagra ar iarratas chun éileamh a laghdú',
      processItemTitle3: 'Teicneolaíocht mhonatóireachta agus rialaithe a shuiteáil',
      processItemDescription3:
        'Beidh do shuíomh ceangailte agus cumasaithe chun cabhrú le cothromú na heangaí le linn buaicéilimh',
      processItemTitle4: 'Trádáil',
      processItemDescription4:
        'Déanfaimid an scéim a bhainistiú ar do shon agus déanfaimid trádáil maidir le do chumas um laghdú éilimh sa mhargadh fuinnimh',
      callToActionText: 'An mian leat éileamh a laghdú agus íocaíocht a fháil dá bharr?',
      callToActionButton: 'Déan teagmháil linn',
    },
  },
  {
    english: {
      headerText: 'Carbon footprint reduction',
      title1: 'Fight climate change & boost your brand',
      subtitle1: 'CARBON FOOTPRINT REDUCTION',
      description1:
        'We work with the global ISO 14064 and Greenhouse Gas Protocol carbon accounting standards. We can calculate the carbon footprint of your company, product or service and help you build a strategy to reduce your emissions. Then we’ll help you achieve formal certification so you can publicise your climate action to the world and achieve competitive advantage.\nReducing your carbon footprint helps the environment and is positive for the perception of your brand, with research by NYU Stern Center for Sustainable Business showing that products marked as sustainable grow 5.6 times faster than those that are not. ',
      title2: 'How does it work?',
      subtitle2: 'CARBON FOOTPRINT REDUCTION',
      description2:
        'According to the Intergovernmental Panel on Climate Change (IPCC), to avoid the most catastrophic impacts of climate change, the world must halve carbon emissions by 2030. Calculating your carbon footprint gives your business the information necessary to start reducing its emissions.\nWe’ll help your business identify the most practical emission reductions and source high quality carbon offsets for the harder to abate emissions. \n',
      processTitle: 'Our Process',
      processSubtitle: 'CARBON FOOTPRINT REDUCTION',
      processItemTitle1: 'Measure Your Carbon Footprint',
      processItemDescription1: 'Your company’s carbon footprint including scope 2 and 3 emissions will be measured',
      processItemTitle2: 'Set Reduction Targets',
      processItemDescription2: 'We’ll help you set practical emissions reduction targets',
      processItemTitle3: 'Reduce Emissions',
      processItemDescription3:
        'We’ll help you make all the physical emission reductions possible and source sustainable carbon offsets for any remaining emissions',
      processItemTitle4: 'Verify Reductions',
      processItemDescription4:
        'Your emission reductions will be verified in line with international carbon accounting standards',
      processItemTitle5: 'Communicate',
      processItemDescription5:
        'Through formal certification you can publicise your climate action in corporate communications',
      callToActionText: 'Want to gain competitive advantage and help save the planet?',
      callToActionButton: 'Get Started',
    },
    irish: {
      headerText: 'Do lorg carbóin a laghdú',
      title1: 'Troid in aghaidh athrú aeráide & cuir borradh faoi do bhranda',
      subtitle1: 'DO LORG CARBÓIN A LAGHDÚ',
      description1:
        'Oibrímid i gcomhar le hEagraíocht Idirnáisiúnta na gCaighdeán (ISO) 14064 agus le caighdeáin chuntasaíochta carbóin Phrótacal Gáis Cheaptha Teasa. Is féidir linn lorg carbóin do chuideachta, do tháirge nó do sheirbhís a ríomh agus cabhrú leat straitéis a cheapadh chun d’astaíochtaí a laghdú. Ansin cabhróimid leat deimhniú foirmiúil a bhaint amach ionas gur féidir leat do ghníomh ar son na haeráide a phoibliú don domhan agus buntáiste iomaíoch a fháil.\nMá laghdaíonn tú do lorg carbóin rachaidh sé chun tairbhe an chomhshaoil agus is rud dearfach é maidir le dearcadh daoine i leith do bhranda. Léiríonn taighde ó NYU Stern Center for Sustainable Business go bhfásann táirgí a bhfuil an marc ‘inbhuanaithe’ orthu 5.6 uaire níos tapa ná iad siúd nach bhfuil sé orthu. \n',
      title2: 'Conas a oibríonn sé?',
      subtitle2: 'DO LORG CARBÓIN A LAGHDÚ',
      description2:
        'Dar leis an bPainéal Idir-Rialtasach ar an Athrú Aeráide (IPCC), chun na tionchair is tubaistí a bhaineann le hathrú aeráide a sheachaint, ní mór don domhan astaíochtaí carbóin a laghdú faoina leath roimh 2030. Trí do lorg carbóin a ríomh tugtar an fhaisnéis is gá do do ghnólacht chun tús a chur le laghdú a astaíochtaí. \nCabhróimid le do ghnó na laghduithe astaíochtaí is praiticiúla a aithint agus cúiteamh carbóin ardchaighdeáin a fháil le haghaidh na n-astaíochtaí is deacra a laghdú. \n',
      processTitle: 'Ár bpróiseas',
      processSubtitle: 'DO LORG CARBÓIN A LAGHDÚ',
      processItemTitle1: 'Do lorg carbóin a thomhas',
      processItemDescription1:
        'Déanfar lorg carbóin do chuideachta a thomhas lena n-áirítear astaíochtaí i réimse 2 agus réimse 3',
      processItemTitle2: 'Socraigh spriocanna maidir le laghduithe',
      processItemDescription2: 'Cabhróimid leat chun spriocanna praiticiúla a leagan síos maidir le laghdú astaíochtaí',
      processItemTitle3: 'Laghdaigh astaíochtaí',
      processItemDescription3:
        'Cabhróimid leat na laghduithe astaíochtaí go léir atá indéanta a dhéanamh agus cúiteamh inbhuanaithe carbóin a fháil le haghaidh aon astaíochtaí atá fágtha',
      processItemTitle4: 'Fíoraigh na laghduithe',
      processItemDescription4:
        'Fíorófar na laghduithe a dhéanfaidh tú ar astaíochtaí de réir na gcaighdeán cuntasaíochta carbóin idirnáisiúnta',
      processItemTitle5: 'Déan cumarsáid',
      processItemDescription5:
        'Trí dheimhniú foirmiúil a fháil is féidir leat do ghníomh ar son na haeráide a phoibliú i gcumarsáid chorparáideach',
      callToActionText: 'An bhfuil tú ag iarraidh buntáiste iomaíoch a fháil agus cabhrú chun an domhan a shábháil?',
      callToActionButton: 'Tosaigh anois',
    },
  },
  {
    english: {
      headerText: 'About us',
      title: 'Our company',
      subtitle: 'ABOUT US',
      description:
        'We created Irish Commodities so that companies can get more from their energy management. By helping you buy energy at the right time, and employing user-friendly technology to reduce your energy usage, we want to drive value to your bottom line. \nWe also realise the best way to fight climate change is by making it profitable for you to reduce your carbon footprint. This is why we help you get formal certification for your climate action that you can then publicise and gain competitive advantage from. And at the end of the day we want to deliver you a service that you can trust and we can be proud of.\n',
      staffName1: 'Ciarán Ó Dálaigh',
      staffRole1: 'CO-FOUNDER & HEAD OF FINANCE',
      staffBio1:
        'Ciarán once caught a fish this big! He heads up our finance department and as well as keeping our books balanced is responsible for statutory accounts, tax, treasury, and payroll.\nCiarán brings with him a wealth of experience; He started his accountancy career in financial services audit and moved into management and fund accounting. Prior to this Ciarán worked in the pharmaceutical industry as a process development chemist. Ciarán is an ACA qualified Chartered Accountant and holds a PhD in Organic Chemistry from Trinity College Dublin.\n\n',
      staffName2: 'Cathal Ó Dálaigh',
      staffRole2: 'CO-FOUNDER & HEAD OF TRADING',
      staffBio2:
        '\nCathal spends his day monitoring trading screens and talking our clients through the latest energy market developments. With more than 15 years energy market experience, he has managed the gas & power portfolios of industrial and commercial energy users in Ireland, the UK and Europe. He has an MSc in Energy Economics from the Centre for Energy, Petroleum and Mineral Law and Policy where he specialized in Carbon Markets. Cathal is also a qualified Technical Analyst of price chart movements.\n',
      callToActionText: 'Leverage our drive and expertise',
      callToActionButton: 'Get in touch',
    },
    irish: {
      headerText: 'Maidir linne',
      title: 'Ár gComhlacht',
      subtitle: 'MAIDIR LINNE',
      description:
        'Chruthaíomar Tráchtearraí Éireannacha le gur féidir le comhlachtaí níos mó a fháil óna mbainistíocht fuinnimh. Trí chabhair a thabhairt duit fuinneamh a cheannach ag an am chuí, agus trí fheidhm a bhaint as teicneolaíocht soláimhsithe chun d’usáid fuinnimh a laghdú, teastaíonn uainn luach breise a sholáthar do do bhunlíne airgeadais. \nTuigimid, chomh maith, gurb é an bealach is fearr chun troid i gcoinne athrú aeráide ná go bhfaigheadh do ghnólacht brabús trí do lorg carbóin a laghdú.  Sin é an fáth go gcuidímid leat deimhniú foirmiúil a fháil faoi do ghníomh ar son na haeráide agus gur féidir leat é sin a phoibliú agus buntáiste iomaíoch a bhaint as. Agus sa deireadh thiar, teastaíonn uainn seirbhís a sholáthar duit ar féidir leat muinín a bheith agat aisti agus ar féidir linn a bheith bródúil aisti.\n',
      staffName1: 'Ciarán Ó Dálaigh',
      staffRole1: 'COMHBHUNAITHEOIR & CEANNASAÍ AIRGEADAIS',
      staffBio1:
        'Coinníonn Ciaran ár leabhair comhardaithe. Chríochnaigh sé a PhD sa Cheimic i gColáiste na Tríonóide, Baile Átha Cliath. Ansin shocraigh sé cáiliú mar Chuntasóir Cairte agus d’oibrigh sé i seirbhísí airgeadais agus i dtionscal na gcistí fálaithe i Londain le breis agus 9 mbliana. Speisialaigh Ciarán i maoiniú infheistíochta iarmharta i margaí i mbéal forbartha san Afraic agus in Oirdheisceart na hÁise agus b’eisean a thionscain ár gcúiteamh inbhuanaithe carbóin.',
      staffName2: 'Cathal Ó Dálaigh',
      staffRole2: 'COMHBHUNAITHEOIR & CEANN TRÁDÁLA',
      staffBio2:
        'Caitheann Cathal a lá ag déanamh monatóireachta ar scáileáin trádála agus ag caint lenár gcliaint faoi na forbairtí is déanaí sa mhargadh fuinnimh. Tar éis níos mó ná 15 bliana a chaitheamh ag obair sa tionscal, rinne sé bainistiú ar phunanna gáis agus cumhachta d’úsáideoirí fuinnimh tionsclaíocha agus tráchtála in Éirinn, sa Ríocht Aontaithe agus san Eoraip. Tá MSc aige in Eacnamaíocht Fuinnimh ón Centre for Energy, Petroleum and Mineral Law and Policy áit a ndearna sé speisialtóireacht i Margaí Carbóin. Is Anailísí Teicniúil cáilithe é Cathal ar ghluaiseachtaí praghsanna sna cairteacha.',
      callToActionText: 'Déan ár ndíograis agus ár saineolas a ghiaráil',
      callToActionButton: 'Déan teagmháil linn',
    },
  },
  {
    english: {
      headerText: 'Talk to us',
      title: 'See how we can drive value to your business',
      subtitle: 'TALK TO US',
      description:
        'We promise your email won’t disappear into the inbox abyss. Or if you are in the mood for an old-fashioned telephone call, we are here to answer your questions.\n',
      email: 'hello@irishcommodities.com',
      phone: '+353 (0) 65 6864502',
      address: 'Irish Commodities\n2 Upper Carmody Street\nEnnis\nIreland\nV95 EW7A',
      copyright:
        'Tráchtearraí Éireannacha Teo. T/A Irish Commodities. Registered in Ireland at 2 Upper Carmody Street, Ennis, V95 EW7A. No. 679304',
      socialMediaTitle: 'Connect with us',
      linkedIn: 'https://www.linkedin.com/company/irish-commodities/?viewAsMember=true',
      twitter: 'https://www.twitter.com/',
      sendSuccessTitle: 'Thank you!',
      sendSuccessSubtitle: "We'll be in touch soon.",
      errorMessage: 'Error! Please try again or email us directly',
    },
    irish: {
      headerText: 'Labhair linn',
      title: 'Féach conas luach do ghnó a mhéadú',
      subtitle: 'LABHAIR LINN',
      description:
        'Geallaimid nach n-imeoidh do r-phost ó radharc go deo i nduibheagán an bhosca isteach. Nó má tá fonn ort glao teileafóin seanaimseartha a dhéanamh, táimid anseo chun do cheisteanna a fhreagairt',
      email: 'hello@irishcommodities.com',
      phone: '+353 (0) 65 6864502',
      address: 'Tráchtearraí Éireannacha\n2 Sráid Uí Chearmada\nInis\nÉire\nV95 EW7A',
      copyright:
        'Tráchtearraí Éireannacha Teo. T/A Irish Commodities. Cláraithe in Éirinn ag 2 Sráid Uí Chearmada Uachtarach, Inis, V95 EW7A. Uimhir 679304',
      socialMediaTitle: 'Ceangail linn',
      sendSuccessTitle: '\nGo raibh maith agat',
      sendSuccessSubtitle: 'Beimid i dteagmháil go luath',
      errorMessage: 'Earráid! Bain triail as arís nó cuir ríomhphost chugainn',
    },
  },
  {
    english: {
      home: 'Home',
      services: 'Services',
      about: 'About',
      contact: 'Talk to us',
      service1: 'Energy procurement',
      service2: 'Demand management',
      service3: 'Demand response',
      service4: 'Carbon footprint reduction',
    },
    irish: {
      home: 'Baile',
      services: 'Seirbhísí',
      about: 'Maidir linne',
      contact: 'Labhair linn',
      service1: 'Soláthar fuinnimh',
      service2: 'Bainistiú éilimh',
      service3: 'Freagra ar éileamh',
      service4: 'Lorg carbóin a laghdú',
    },
  },
]

const fetchData = async ({ language, sheetNum }) => {
  // const result = await GetSheetDone.labeledColsRows(process.env.REACT_APP_GOOGLE_SHEET_ID, sheetNum)
  // const structuredData = await Object.keys(result.data).reduce((obj, key) => {
  //   const data = result.data
  //   const formattedKey = camelCase(key)
  //   const text = get(data, `${key}[${language}]`)
  //   return (obj = {
  //     ...obj,
  //     [formattedKey]: text,
  //   })
  // }, {})

  // return await structuredData

  return DATA[sheetNum - 1][language]
}

export const withCMS = (Wrapped) => {
  const WithCMS = ({ ...props }) => {
    const { sheetNum, language, hideLoadingSpinner = false } = props
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      if (!isUndefined(language)) {
        fetchData({ language, sheetNum }).then((res) => {
          setData(res)
          setIsLoading(false)
        })
      }
    }, [language, sheetNum])

    if (isLoading) {
      if (!hideLoadingSpinner) {
        return (
          <div style={{ flex: 1, minHeight: 'calc(100vh - 110px)', top: 0 }} className="column xy-center">
            <LoadingSpinner />
          </div>
        )
      } else {
        return null
      }
    } else {
      return <Wrapped data={data} {...props} />
    }
  }

  return WithCMS
}
