import React from 'react'
import Icon from './Icon'

const MeasurementIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <g clipPath="url(#a_svg__clip0)" fill="currentColor">
      <path d="M43.133 15.018L45 13.15 34.018 2.168l-20.42 20.419c-3.112-.91-6.663-.24-9.196 1.761l-2.535-2.534L0 23.68l2.535 2.534c-4.847 6.308-1.112 15.404 6.732 16.617L20.51 31.588l19.596-19.596 3.026 3.026zM3.015 32.55c-.085-4.15 3.571-7.807 7.721-7.721 3.312-.049 6.43 2.262 7.374 5.425l-9.67 9.67c-3.163-.946-5.473-4.062-5.425-7.374zm17.134-4.335a10.442 10.442 0 00-3.962-4.481l2.253-2.254 2.235 2.235 1.867-1.867-2.235-2.235 2.027-2.027 2.235 2.235 1.867-1.868-2.234-2.234 2.027-2.028 2.235 2.235 1.867-1.867-2.235-2.235 2.027-2.027 2.235 2.235 1.867-1.868-2.234-2.234 2.027-2.027 4.222 4.221-18.09 18.09z" />
      <path d="M5.882 34.663l6.944-6.944 1.867 1.867L7.75 36.53l-1.867-1.867z" />
    </g>
    <defs>
      <clipPath id="a_svg__clip0">
        <path fill="#fff" d="M0 0h45v45H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default MeasurementIcon
