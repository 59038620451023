import React from 'react'
import Icon from './Icon'

const TargetIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path d="M22.5 28.125a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25z" fill="currentColor" />
    <path
      d="M24.375 7.63V3.75h-3.75v3.88A15.015 15.015 0 007.629 20.624H3.75v3.75h3.88a15.013 15.013 0 0012.995 12.996v3.879h3.75v-3.88a15.012 15.012 0 0012.996-12.995h3.879v-3.75h-3.88A15.015 15.015 0 0024.376 7.629zM22.5 33.75c-6.204 0-11.25-5.046-11.25-11.25s5.046-11.25 11.25-11.25 11.25 5.046 11.25 11.25-5.046 11.25-11.25 11.25z"
      fill="currentColor"
    />
  </Icon>
)

export default TargetIcon
