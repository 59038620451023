import React from 'react'
import { Helmet } from 'react-helmet'
import { withCMS } from '../../components/app/WithCMS'
import { Header, View } from '../../components/ui'
import { CallToAction, TextBlock } from '../../components/common'
import { OurProcess } from '../../components/service'

const Service = ({ data, icons, icons2, video, backgroundPosition, keywords, description, ...rest }) => {
  return (
    <>
      <Helmet>
        <title>{data?.headerText}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <View>
        <Header title={data?.headerText} video={video} backgroundPosition={backgroundPosition} />
        <TextBlock title={data?.title1} subtitle={data?.subtitle1} description={data?.description1} number="01" />
        <TextBlock
          title={data?.title2}
          subtitle={data?.subtitle2}
          description={data?.description2}
          number="02"
          reverse
          background="dark"
        />
        {data?.title3 && (
          <TextBlock
            title={data?.title3}
            subtitle={data?.subtitle3}
            description={data?.description3}
            number="03"
            background="light"
          />
        )}
        <OurProcess
          key={data?.processTitle}
          title={data?.processTitle}
          subtitle={data?.processSubtitle}
          description={data?.processDescription}
          number={data?.title3 ? '04' : '03'}
          data={data}
          icons={icons}
          icons2={icons2}
        />
        <CallToAction text={data?.callToActionText} buttonText={data?.callToActionButton} />
      </View>
    </>
  )
}

export default withCMS(Service)
