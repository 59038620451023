import React from 'react'
import cn from 'classnames'
import { Title } from '../common/'
import { Section } from '../ui'
import { AnimateOnReveal } from '../app'

const StaffBlock = ({ title, subtitle, number, description, background, reverse = false, image, alt, className }) => {
  return (
    <Section
      className={cn('TextBlock StaffBlock mt-0 py-48', background)}
      contentClassName={cn('row y-center even', { reverse: reverse })}
    >
      <div className="image-wrapper row xy-center">
        <img className={className} src={image} alt={alt} />
      </div>
      <div className={cn('text-wrapper', { 'mr-64': reverse }, { 'ml-64': !reverse })}>
        <Title className="mb-24 l" title={title} subtitle={subtitle} light={background === 'dark'} />
        <AnimateOnReveal>
          <p className={cn({ 'text-light': background === 'dark' }, { 'text-primary': background === 'light' })}>
            {description}
          </p>
        </AnimateOnReveal>
      </div>
    </Section>
  )
}

export default StaffBlock
