import React from 'react'
import Icon from './Icon'

const RiskIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.4 13.125H25a2.4 2.4 0 00-2.4 2.4v25a2.4 2.4 0 002.4 2.4h16.4a2.4 2.4 0 002.4-2.4v-25a2.4 2.4 0 00-2.4-2.4zm-16.4-1.2a3.6 3.6 0 00-3.6 3.6v25a3.6 3.6 0 003.6 3.6h16.4a3.6 3.6 0 003.6-3.6v-25a3.6 3.6 0 00-3.6-3.6H25z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.4 16.125H33a.4.4 0 00-.4.4v4c0 .22.179.4.4.4h7.4a.4.4 0 00.4-.4v-4a.4.4 0 00-.4-.4zm-7.4-1a1.4 1.4 0 00-1.4 1.4v4a1.4 1.4 0 001.4 1.4h7.4a1.4 1.4 0 001.4-1.4v-4a1.4 1.4 0 00-1.4-1.4H33zM40.6 24.725H25.8a.6.6 0 00-.6.6v15a.6.6 0 00.6.6h14.8a.6.6 0 00.6-.6v-15a.6.6 0 00-.6-.6zm-14.8-1a1.6 1.6 0 00-1.6 1.6v15a1.6 1.6 0 001.6 1.6h14.8a1.6 1.6 0 001.6-1.6v-15a1.6 1.6 0 00-1.6-1.6H25.8z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.8 32.225h7.9v1.2h-7.9v7.8h-1.2v-7.8h-7.9v-1.2h7.9v-7.8h1.2v7.8z"
      fill="currentColor"
    />
    <path
      d="M28.469 29.075H27V28h1.469v-1.474h1.062v1.474h1.47v1.076h-1.47v1.45H28.47v-1.45zM28.235 36.922L27.22 35.86l.778-.744 1.015 1.062 1.065-1.02.734.768-1.065 1.019 1.016 1.061-.778.744-1.015-1.061-1.048 1.002-.734-.767 1.047-1.002zM35.4 27.925h4.2v1.2h-4.2v-1.2zM35.4 34.925h4.2v1.2h-4.2v-1.2zM35.4 37.725h4.2v1.2h-4.2v-1.2z"
      fill="currentColor"
    />
    <path
      d="M34.6 14.725c0 6.517-5.283 11.8-11.8 11.8S11 21.242 11 14.725s5.283-11.8 11.8-11.8 11.8 5.283 11.8 11.8z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8 25.325c5.854 0 10.6-4.746 10.6-10.6 0-5.854-4.746-10.6-10.6-10.6-5.854 0-10.6 4.746-10.6 10.6 0 5.854 4.746 10.6 10.6 10.6zm0 1.2c6.517 0 11.8-5.283 11.8-11.8s-5.283-11.8-11.8-11.8S11 8.208 11 14.725s5.283 11.8 11.8 11.8z"
      fill="currentColor"
    />
    <path d="M31.6 14.725a8.8 8.8 0 11-17.6 0 8.8 8.8 0 0117.6 0z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8 22.325a7.6 7.6 0 100-15.2 7.6 7.6 0 000 15.2zm0 1.2a8.8 8.8 0 100-17.6 8.8 8.8 0 000 17.6z"
      fill="currentColor"
    />
    <path
      d="M20.6 17.051c0-.298.062-.594.183-.87.12-.276.297-.526.52-.737.223-.211.487-.379.779-.493a2.518 2.518 0 011.837 0c.29.114.555.282.778.493.223.21.4.461.52.737.121.276.183.572.183.87 0 .603-.253 1.182-.703 1.608a2.47 2.47 0 01-1.697.666 2.47 2.47 0 01-1.697-.666 2.215 2.215 0 01-.703-1.608zM20.835 3.4c-.032-.287 0-.577.094-.852.094-.274.248-.526.452-.74.203-.215.453-.387.73-.504a2.275 2.275 0 011.768 0c.278.117.527.289.73.503.204.215.358.467.452.741.094.275.126.565.094.852l-.84 7.973c-.028.313-.18.605-.424.818-.244.212-.564.33-.896.33-.332 0-.651-.118-.896-.33a1.232 1.232 0 01-.424-.818l-.84-7.973z"
      fill="currentColor"
    />
    <path d="M10.775 23.05l2.643-2.643 3.725 3.725-2.643 2.643-3.725-3.725z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.418 21.821l-1.229 1.229 2.311 2.31 1.229-1.228-2.311-2.31zm-2.643 1.229l3.725 3.725 2.643-2.643-3.725-3.725-2.643 2.643z"
      fill="currentColor"
    />
    <path
      d="M2.5 34.649a3.536 3.536 0 010-5.001l7.923-7.923 5.004 5.004-7.927 7.92a3.536 3.536 0 01-5 0z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.423 23.14l-7.215 7.215a2.536 2.536 0 003.586 3.587l7.218-7.214-3.589-3.589zm5.004 3.589L7.5 34.649a3.536 3.536 0 01-5-5.001l7.923-7.923 5.004 5.004z"
      fill="currentColor"
    />
  </Icon>
)

export default RiskIcon
