import React from 'react'
import Icon from './Icon'

const EnergyIcon = (props) => (
  <Icon viewBox="0 0 63 66" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M6.906 29.08H4.51c-.765 0-1.386-.65-1.386-1.452v-1.416c0-.802.62-1.452 1.386-1.452h2.397c.766 0 1.386.65 1.386 1.452v1.416c0 .802-.62 1.452-1.386 1.452zM45.262 29.08h-2.397c-.766 0-1.386-.65-1.386-1.452v-1.416c0-.802.62-1.452 1.386-1.452h2.397c.766 0 1.386.65 1.386 1.452v1.416c0 .802-.62 1.452-1.386 1.452z"
        fill="#FFE645"
      />
      <path
        d="M47.009 61.635h-2.883L32.431 35.484V21.408h13.004c3.482 0 4.456-5.206 1.22-6.575-2.198-.929-11.008-4.862-13.282-5.601l2.336-2.4c2.056-2.113.565-5.865-2.3-5.865H16.146c-2.865 0-4.357 3.752-2.3 5.865l2.33 2.395S5.08 13.904 2.881 14.833c-3.236 1.368-2.263 6.575 1.22 6.575h13.026v14.076L5.397 61.635H2.527c-2.03 0-2.123 3.398 0 3.398h44.48c2.031 0 2.124-3.398 0-3.398zm-6.49 0L26.893 44.431l3.786-4.779 9.842 21.983zm-11.367-25.39l-4.373 5.52-.017.022-4.368-5.513.013-.03V12.007h8.745v24.239zM24.777 47.14l.09.113v-.005l11.396 14.387H13.297L24.778 47.14zm20.66-29.143H32.431v-5.498l13.004 5.498zm-28.31 0H4.1l13.027-5.508v5.508zm-.98-13.606h17.262l-4.257 4.375v.003h-8.745l-4.26-4.378zm2.722 35.29l3.78 4.773L9.04 61.635l9.83-21.954z"
        fill="#E8F4FF"
      />
      <path
        d="M47.008 61.635h-2.883L32.43 35.484V21.408h13.005c3.481 0 4.456-5.206 1.219-6.575-2.198-.929-11.008-4.862-13.282-5.601l2.336-2.4c2.056-2.113.565-5.865-2.3-5.865h-4.257V4.39h4.257l-4.257 4.375v27.479l1.525 3.407 9.842 21.983v3.398h6.489c2.03 0 2.123-3.398 0-3.398zM32.431 12.499l13.005 5.498H32.43v-5.498z"
        fill="#DCEAF7"
      />
      <path
        d="M44.009 32.104c.51 0 .923-.433.923-.967v-1.09c1.08.062 2.196-.566 2.534-1.699 1.964 1.255 6.847 3.756 14.593 3.756 1.194 0 1.194-1.934 0-1.934-9.41 0-14.357-3.975-14.425-4.03 0 0-.587-2.476-2.702-2.347v-1.418h.504c2.332 0 3.707-1.788 4.036-3.56.328-1.77-.31-3.964-2.471-4.878-.56-.237-1.583-.683-2.767-1.2-2.82-1.232-6.795-2.967-9.158-3.9l1.28-1.315C38.948 4.858 37.033 0 33.41 0H16.147c-3.622 0-5.54 4.857-2.947 7.522l1.323 1.36-4.76 2.006c-1.106.466-.417 2.26.69 1.794l6.068-2.558c.647-.415.748-.944.302-1.587l-2.33-2.396c-1.43-1.468-.359-4.207 1.654-4.207h17.262c1.992 0 3.098 2.724 1.654 4.207l-2.335 2.4c-.459.686-.334 1.224.373 1.614 1.648.536 6.928 2.84 10.423 4.366 1.239.541 2.217.968 2.786 1.209 1.269.536 1.52 1.798 1.35 2.716-.17.92-.855 1.995-2.224 1.995H32.432c-.51 0-.923.433-.923.967v14.076c0 .142.03.282.087.41L43.29 62.045a.92.92 0 0 0 .835.557h2.883c.871 0 .842 1.465 0 1.465H27.883c-1.193 0-1.193 1.933 0 1.933h19.125c3.208 0 3.225-5.332 0-5.332h-2.296L33.454 35.492c4.329 1.219 13.083 2.877 22.326 2.877 1.986 0 3.994-.077 5.986-.25 1.189-.105 1.036-2.032-.153-1.928-11.484 1.003-23.5-1.294-28.258-2.734V22.375h9.731v1.418c-1.31-.038-2.53.977-2.53 2.419v1.416c0 1.464 1.224 2.43 2.53 2.419v1.09c0 .534.413.967.923.967zm-1.607-4.476v-1.416c0-.268.207-.485.463-.485h2.397c.256 0 .463.217.463.485v1.416a.475.475 0 0 1-.463.485h-2.397a.475.475 0 0 1-.463-.485z"
        fill="#060606"
      />
      <path
        d="M34.264 4.026a.924.924 0 0 0-.855-.602H16.148c-.81 0-1.225 1.063-.646 1.657l4.26 4.378a.91.91 0 0 0 .646.277h8.745a.91.91 0 0 0 .677-.311l4.227-4.344a1 1 0 0 0 .208-1.055zm-5.492 3.776h-7.988l-2.378-2.444H31.15l-2.379 2.444zM22.69 64.066H2.527c-.87 0-.84-1.464 0-1.464h2.869a.92.92 0 0 0 .835-.556l11.73-26.15c.058-.13.088-.27.088-.412V21.408c0-.534-.413-.967-.923-.967H4.101c-1.53 0-2.559-1.624-2.176-3.113.446-1.734 2.67-2.176 4.057-2.761 1.107-.467.418-2.26-.689-1.794l-2.757 1.164c-4.064 1.718-2.881 8.438 1.565 8.438h.63v1.418h-.222c-1.273 0-2.309 1.085-2.309 2.419v1.416c0 1.463 1.224 2.43 2.53 2.419v1.09c0 1.25 1.846 1.25 1.846 0v-1.09c.974.042 1.966-.41 2.409-1.373 1.541 1.1 4.107 2.623 7.22 3.586v3.008L4.81 60.668H2.528c-3.157 0-3.262 5.332 0 5.332H22.69c1.194 0 1.194-1.934 0-1.934zM7.37 27.628a.475.475 0 0 1-.464.485H4.51a.475.475 0 0 1-.463-.485v-1.416c0-.268.208-.486.463-.486h2.397c.256 0 .464.218.464.486v1.416zm1.845-1.165c.097-1.616-1.163-2.758-2.64-2.67v-1.418h9.63v7.862c-3.326-1.125-5.931-2.907-6.99-3.773z"
        fill="#060606"
      />
      <path
        d="M13.295 62.602h22.968c.698 0 1.239-.918.71-1.584L25.578 46.63c-.382-.482-1.06-.676-1.51-.108L12.585 61.018c-.528.666.012 1.584.71 1.584zm11.484-13.95l9.52 12.016H15.26l9.52-12.017zM31.51 12.499v5.498c0 .534.412.966.922.966h13.004c.442 0 .822-.328.906-.782a.977.977 0 0 0-.56-1.08l-13.005-5.499c-.726-.172-1.148.127-1.268.897zm1.845 1.433l7.329 3.098h-7.33v-3.098zM3.755 17.1c-.918.388-.645 1.863.345 1.863h13.027c.51 0 .923-.433.923-.966v-5.508c-.12-.77-.542-1.068-1.268-.897L3.755 17.1zm12.45-.07H8.852l7.351-3.108v3.108zM40.52 62.602c.626 0 1.106-.772.835-1.378l-9.842-21.982c-.443-.663-.958-.732-1.545-.207l-3.786 4.779a1.002 1.002 0 0 0 0 1.234L39.81 62.252c.18.23.444.35.71.35zm-12.429-18.17l2.369-2.99 6.157 13.752L28.09 44.43zM18.752 38.722a.927.927 0 0 0-.719.549L8.204 61.225c-.438.978.85 1.907 1.546 1.027l13.61-17.18a1.002 1.002 0 0 0 0-1.236l-3.78-4.772a.902.902 0 0 0-.828-.342zm2.698 5.732l-8.507 10.74 6.144-13.724 2.363 2.984zM19.484 36.111a1 1 0 0 0 .2.78l4.367 5.514a.896.896 0 0 0 1.42.001l4.253-5.368c.17-.141.353-.58.351-.802v-24.23c0-.556-.524-1.073-1.088-.966h-8.415c-.564-.107-1.088.41-1.088.966v24.105zm6.69-3.88l2.055-1.903v3.807l-2.056-1.903zm2.055-6.192l-2.056-1.904 2.056-1.904v3.808zm-3.45-3.194l-2.978-2.759 2.979-2.758 2.979 2.758-2.98 2.759zm-3.45-.614l2.057 1.904-2.056 1.903v-3.807zm0 8.097l2.057 1.904-2.056 1.903v-3.807zm.472-2.145l2.979-2.758 2.979 2.758-2.98 2.758-2.978-2.758zm6.428-10.241l-2.056-1.904 2.056-1.904v3.808zm-3.45-3.194l-1.916-1.775h3.834l-1.917 1.775zm-1.393 1.29l-2.056 1.904v-3.808l2.056 1.904zm1.376 24.236l-3.078-3.886 3.096-2.866 3.075 2.847-3.093 3.905z"
        fill="#060606"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h63v66H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default EnergyIcon
