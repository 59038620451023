import React from 'react'
import Icon from './Icon'

const CommunicateIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M4.86 26.438l.255.002v9.424a.527.527 0 00.527.527 5.006 5.006 0 005-5v-4.337a27.76 27.76 0 015.932 1.983l11.1 5.18a.527.527 0 00.75-.478v-7.343a5 5 0 000-9.916V9.137a.527.527 0 00-.75-.478l-11.1 5.18A27.884 27.884 0 014.86 16.437a2.767 2.767 0 00-2.764 2.764v4.473a2.767 2.767 0 002.764 2.764zm26.87-5a3.951 3.951 0 01-3.307 3.893v-7.786a3.95 3.95 0 013.307 3.893zM9.588 31.39A3.952 3.952 0 016.17 35.3v-8.83c1.146.055 2.288.182 3.418.378v4.541zm17.78 1.52l-2.634-1.23V11.195l2.635-1.23V32.91zM17.02 14.794l6.659-3.107v19.502l-6.66-3.108a28.83 28.83 0 00-6.376-2.105V16.9a28.83 28.83 0 006.377-2.105zM3.15 19.201a1.71 1.71 0 011.71-1.709 28.66 28.66 0 004.728-.395v8.683a28.66 28.66 0 00-4.728-.395 1.712 1.712 0 01-1.71-1.71V19.2z"
      fill="currentColor"
    />
    <path
      d="M32.149 29.024a.528.528 0 00.413.971 9.3 9.3 0 000-17.115.527.527 0 10-.413.97 8.245 8.245 0 010 15.175z"
      fill="currentColor"
    />
    <path
      d="M34.452 8.651a.527.527 0 10-.413.97 12.842 12.842 0 010 23.632.527.527 0 00.413.97 13.896 13.896 0 000-25.572z"
      fill="currentColor"
    />
  </Icon>
)

export default CommunicateIcon
