import React from 'react'
import Icon from './Icon'

const PropertySuppliersIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.022 5.369H7.62v35.387h30.403V5.37zM7.62 3.375a1.994 1.994 0 00-1.994 1.994v35.387c0 1.101.893 1.994 1.994 1.994h30.403a1.994 1.994 0 001.994-1.994V5.37a1.994 1.994 0 00-1.994-1.994H7.62z"
      fill="currentColor"
    />
    <path
      d="M10.976 12.23l.776-.777a.499.499 0 01.705 0l1.052 1.052c.19.19.496.195.692.011l3.034-2.831a.498.498 0 01.693.012l.553.553a.498.498 0 010 .705l-4.293 4.293a.499.499 0 01-.69.014l-2.507-2.314a.498.498 0 01-.015-.719z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.065 9.502a.748.748 0 011.04.018l.552.553a.748.748 0 010 1.058l-4.293 4.293a.748.748 0 01-1.036.02l-2.506-2.313a.748.748 0 01-.022-1.078l.776-.776a.748.748 0 011.057 0l1.052 1.051a.25.25 0 00.346.006l3.034-2.832zm.687.37a.25.25 0 00-.346-.005l-3.035 2.831a.748.748 0 01-1.038-.017l-1.052-1.052a.249.249 0 00-.352 0l-.776.776a.25.25 0 00.007.36l2.506 2.313a.25.25 0 00.346-.007l4.293-4.293a.25.25 0 000-.352l-.553-.553z"
      fill="currentColor"
    />
    <path
      d="M10.976 22.696l.776-.776a.499.499 0 01.705 0l1.052 1.051c.19.19.496.195.692.012l3.034-2.832a.498.498 0 01.693.012l.553.553a.498.498 0 010 .705l-4.293 4.294a.498.498 0 01-.69.013l-2.507-2.314a.498.498 0 01-.015-.718z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.065 19.97a.748.748 0 011.039.017l.553.553a.748.748 0 010 1.058l-4.293 4.293a.748.748 0 01-1.036.02l-2.506-2.313a.748.748 0 01-.022-1.078l.776-.776a.748.748 0 011.057 0l1.052 1.051a.25.25 0 00.346.006l3.034-2.832zm.686.37a.25.25 0 00-.346-.006l-3.034 2.832a.748.748 0 01-1.039-.018l-1.051-1.052a.25.25 0 00-.353 0l-.776.776a.25.25 0 00.008.36l2.506 2.313a.25.25 0 00.345-.007l4.294-4.293a.25.25 0 000-.352l-.554-.553z"
      fill="currentColor"
    />
    <path
      d="M10.976 33.163l.776-.776a.498.498 0 01.705 0l1.052 1.051c.19.19.496.195.692.012l3.034-2.832a.498.498 0 01.693.012l.553.553a.498.498 0 010 .705l-4.293 4.293a.498.498 0 01-.69.014l-2.507-2.314a.498.498 0 01-.015-.718z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.065 30.436a.748.748 0 011.039.018l.553.553a.748.748 0 010 1.057l-4.293 4.294a.748.748 0 01-1.036.02l-2.506-2.314a.748.748 0 01-.022-1.078l.776-.776a.748.748 0 011.057 0l1.052 1.052a.25.25 0 00.346.006l3.034-2.832zm.686.37a.25.25 0 00-.346-.006l-3.034 2.832a.748.748 0 01-1.039-.018l-1.051-1.051a.25.25 0 00-.353 0l-.776.776a.25.25 0 00.008.36l2.506 2.313a.25.25 0 00.345-.007l4.294-4.293a.25.25 0 000-.352l-.554-.554z"
      fill="currentColor"
    />
    <path
      d="M20.578 9.855c0-.276.223-.499.498-.499h13.457c.276 0 .499.223.499.499v.996a.498.498 0 01-.499.499H21.076a.498.498 0 01-.498-.498v-.997zM20.578 20.321c0-.275.223-.498.498-.498h13.457c.276 0 .499.223.499.498v.997a.498.498 0 01-.499.499H21.076a.498.498 0 01-.498-.499v-.997zM20.578 30.788c0-.275.223-.498.498-.498h13.457c.276 0 .499.223.499.498v.997a.498.498 0 01-.499.498H21.076a.498.498 0 01-.498-.498v-.997zM20.578 13.344c0-.276.223-.499.498-.499h4.486c.275 0 .498.223.498.498v.997a.498.498 0 01-.498.499h-4.486a.498.498 0 01-.498-.499v-.996zM20.578 23.81c0-.275.223-.498.498-.498h4.486c.275 0 .498.223.498.498v.997a.498.498 0 01-.498.499h-4.486a.498.498 0 01-.498-.499v-.997zM20.578 34.277c0-.275.223-.498.498-.498h4.486c.275 0 .498.223.498.498v.997a.498.498 0 01-.498.498h-4.486a.498.498 0 01-.498-.498v-.997z"
      fill="#99ABD5"
    />
  </Icon>
)

export default PropertySuppliersIcon
