import React from 'react'
import Icon from './Icon'

const PhoneIcon = (props) => (
  <Icon viewBox="0 0 48 48" {...props}>
    <path
      d="M31.377 29.174l-3.586 4.483a37.827 37.827 0 0 1-13.447-13.446l4.482-3.586a3.045 3.045 0 0 0 .88-3.614l-4.086-9.2A3.046 3.046 0 0 0 12.068 2.1L4.291 4.115A3.063 3.063 0 0 0 2.03 7.5 45.2 45.2 0 0 0 40.5 45.971a3.064 3.064 0 0 0 3.384-2.261l2.016-7.779a3.049 3.049 0 0 0-1.712-3.551l-9.2-4.081a3.045 3.045 0 0 0-3.611.875z"
      fill="currentColor"
    />
  </Icon>
)

export default PhoneIcon
