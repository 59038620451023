import React from 'react'
import Icon from './Icon'

const SiteSurveyIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <g clipPath="url(#a_svg__clip0)" fill="currentColor">
      <path d="M2.25 45h4.5A2.25 2.25 0 009 42.75V2.25A2.25 2.25 0 006.75 0h-4.5A2.25 2.25 0 000 2.25v40.5A2.25 2.25 0 002.25 45zM1.5 2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v1.5H5.25a.75.75 0 000 1.5H7.5v1.5h-.75a.75.75 0 000 1.5h.75v1.5H5.25a.75.75 0 000 1.5H7.5v1.5h-.75a.75.75 0 000 1.5h.75v1.5H5.25a.75.75 0 000 1.5H7.5v1.5h-.75a.75.75 0 000 1.5h.75v1.5H5.25a.75.75 0 000 1.5H7.5v1.5h-.75a.75.75 0 000 1.5h.75v1.5H5.25a.75.75 0 000 1.5H7.5v1.5h-.75a.75.75 0 000 1.5h.75v1.5H5.25a.75.75 0 000 1.5H7.5v7.5a.75.75 0 01-.75.75h-4.5a.75.75 0 01-.75-.75V2.25zM27.75 14.25a4.5 4.5 0 100-9 4.5 4.5 0 000 9zm0-7.5a3 3 0 110 6 3 3 0 010-6z" />
      <path d="M12.3 45h2.914a1.5 1.5 0 001.418-1.012l5.128-14.873c.237.087.488.132.74.135h2.25V43.5a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5V29.25H33c.252-.003.502-.048.74-.135l5.127 14.872A1.5 1.5 0 0040.285 45H43.2a1.5 1.5 0 001.417-1.989L37.725 23.02A2.246 2.246 0 0039 21v-9.75A2.25 2.25 0 0036.75 9h-1.5V2.25A2.25 2.25 0 0033 0H22.5a2.25 2.25 0 00-2.25 2.25V9h-1.5a2.25 2.25 0 00-2.25 2.25V21a2.245 2.245 0 001.275 2.02L10.884 43.01A1.5 1.5 0 0012.3 45zm16.95-1.5h-3V29.25h3V43.5zM30 27.75h-7.5a.75.75 0 01-.75-.75v-3.75h12V27a.75.75 0 01-.75.75h-3zM40.285 43.5l-5.318-15.428A2.23 2.23 0 0035.25 27v-3.75h.965L43.2 43.5h-2.915zM22.5 1.5H33a.75.75 0 01.75.75V3h-1.5a.75.75 0 100 1.5h1.5V15h-1.5a.75.75 0 100 1.5h1.5v.75A.75.75 0 0133 18H22.5a.75.75 0 01-.75-.75v-.75h1.5a.75.75 0 000-1.5h-1.5V4.5h1.5a.75.75 0 000-1.5h-1.5v-.75a.75.75 0 01.75-.75zM18 11.25a.75.75 0 01.75-.75h1.5v6.75a2.25 2.25 0 002.25 2.25H33a2.25 2.25 0 002.25-2.25V10.5h1.5a.75.75 0 01.75.75V21a.75.75 0 01-.75.75h-18A.75.75 0 0118 21v-9.75zm1.285 12h.965V27c.003.375.1.744.284 1.072L15.215 43.5H12.3l6.985-20.25zM4.5 42a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zm0-3a.75.75 0 110 1.5.75.75 0 010-1.5z" />
    </g>
    <defs>
      <clipPath id="a_svg__clip0">
        <path fill="#fff" d="M0 0h45v45H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default SiteSurveyIcon
