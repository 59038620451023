import {
  AuctionIcon,
  ContractRequirementsIcon,
  ControlIcon,
  // InstallIcon,
  MonitoringIcon,
  PropertySuppliersIcon,
  RiskIcon,
  SavingsIcon,
  SiteSurveyIcon,
  TailoredIcon,
  TradingIcon,
  MeasurementIcon,
  TargetIcon,
  EmissionsIcon,
  CommunicateIcon,
  VerifyIcon,
} from '../components/icons'

// ENERGY
export const energyKeywords =
  'energy procurement, Market analysis & timing, irish commodities, energy auction, define contract, prequalify suppliers, time market entry, energy auction'

export const energyDescription =
  'We procure gas and electricity in Ireland and across Europe’s deregulated energy markets. Through our premium market analysis and unique online energy auction we’ll transform the way you buy energy and save you time and money in the process.'

export const energyIcons = [ContractRequirementsIcon, PropertySuppliersIcon, TradingIcon, AuctionIcon]
export const energyIcons2 = [ContractRequirementsIcon, PropertySuppliersIcon, AuctionIcon, RiskIcon, TradingIcon]

// DEMAND MANAGEMENT
export const demandManagementKeywords =
  'demand management, onetise demand flexibility, ISO 50001, energy demand management, reduce comsumption, energy management solution, site survey, install monitoring devices, ongoing monitoring, recommended cost-savings, sustainable energy authority, energy management software'
export const demandManagementDescription =
  'How and when you use energy will impact your company’s energy spend. Our user-friendly energy management solution makes it easy to understand your energy use and make cost-saving energy reduction decisions.'
export const demandManagementIcons = [SiteSurveyIcon, ControlIcon, MonitoringIcon, SavingsIcon]

// DEMAND RESPONSE
export const demandResponseKeywords =
  'demand response, monetise demand flexibility, demand side unit, reducing consumption'
export const demandResponseDescription =
  'Once you can monitor and understand your energy usage, you can monetise flexibility in your demand by participating in demand response. Participants can receive substantial payments from Eirgrid for reducing consumption during times of peak electricity demand.'
export const demandResponseIcons = [SiteSurveyIcon, TailoredIcon, ControlIcon, TradingIcon]

// CARBON FOOTPRINT
export const carbonFootprintKeywords =
  'carbon footprint reduction, fight climate change, boost your brand, iso 14064, greenhouse gas protocol, carbon footprint, reduce emissions'
export const carboonFootprintDescription =
  'We work with the global ISO 14064 and Greenhouse Gas Protocol carbon accounting standards. We can calculate the carbon footprint of your company, product or service and help you build a strategy to reduce your emissions.'

export const carbonFootprintIcons = [MeasurementIcon, TargetIcon, EmissionsIcon, VerifyIcon, CommunicateIcon]
