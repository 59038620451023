import React from 'react'
import Icon from './Icon'

const ControlIcon = (props) => (
  <Icon viewBox="0 0 45 45" {...props}>
    <path
      d="M22.5 4.688c9.838 0 17.813 7.974 17.813 17.812 0 9.838-7.975 17.813-17.813 17.813-9.837 0-17.812-7.975-17.812-17.813S12.663 4.687 22.5 4.687zm0 1.875c-8.802 0-15.937 7.135-15.937 15.937S13.698 38.438 22.5 38.438 38.438 31.302 38.438 22.5 31.302 6.562 22.5 6.562zm10.038 5.9c.338.337.399.947 0 1.325l-6.807 6.807a3.75 3.75 0 11-1.326-1.326l6.807-6.807a.938.938 0 011.326 0zM22.5 20.624a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm11.08-3.21a12.145 12.145 0 011.108 5.085.937.937 0 11-1.875 0 10.27 10.27 0 00-.937-4.302.938.938 0 111.703-.783zM22.5 10.313c1.794 0 3.535.388 5.129 1.128a.938.938 0 01-.79 1.7 10.268 10.268 0 00-4.339-.954c-5.695 0-10.312 4.618-10.312 10.313a.937.937 0 11-1.875 0c0-6.731 5.456-12.188 12.187-12.188z"
      fill="currentColor"
    />
  </Icon>
)

export default ControlIcon
