import React from 'react'
import Icon from './Icon'

const DemandManagementIcon = (props) => (
  <Icon viewBox="0 0 47 49" {...props}>
    <path
      d="M34.975 43.725h-22.95c-.76 0-1.377-.642-1.377-1.435s.617-1.436 1.377-1.436h22.95c.76 0 1.377.643 1.377 1.436 0 .793-.617 1.435-1.377 1.435z"
      fill="#AA9EA9"
    />
    <path d="M17.992 35.112h11.016v5.742H17.992v-5.742z" fill="#918291" />
    <path
      d="M43.558 35.112H3.442c-1.52 0-2.754-1.285-2.754-2.871V7.358c0-1.586 1.233-2.87 2.754-2.87h40.116c1.52 0 2.754 1.284 2.754 2.87v24.883c0 1.586-1.233 2.871-2.754 2.871z"
      fill="#F5F8F9"
    />
    <path
      d="M12.622 35.112h-9.18c-1.52 0-2.754-1.285-2.754-2.871V7.358c0-1.586 1.233-2.87 2.754-2.87h9.18c-1.521 0-2.754 1.284-2.754 2.87v24.883c0 1.586 1.233 2.871 2.754 2.871z"
      fill="#E8EDF2"
    />
    <path
      d="M43.558 35.112H3.442c-1.52 0-2.754-1.285-2.754-2.871v-1.914h45.623v1.914c0 1.586-1.232 2.871-2.753 2.871z"
      fill="#AA9EA9"
    />
    <path d="M29.008 35.112H17.992v5.742h4.59v-3.158c0-.37.288-.67.643-.67h5.783v-1.914z" fill="#7A6D79" />
    <path
      d="M12.622 35.112h-9.18c-1.52 0-2.754-1.285-2.754-2.871v-1.914h9.18v1.914c0 1.586 1.233 2.871 2.754 2.871z"
      fill="#9B8E99"
    />
    <path
      d="M18.192 9.414c1.014 0 1.836.857 1.836 1.914s-.822 1.914-1.836 1.914c-1.014 0-1.836-.857-1.836-1.914s.822-1.914 1.836-1.914z"
      fill="#FEE97D"
    />
    <path
      d="M25.857 17.002c1.014 0 1.836.857 1.836 1.914s-.822 1.914-1.836 1.914c-1.014 0-1.836-.857-1.836-1.914 0-1.058.822-1.914 1.836-1.914z"
      fill="#ADE194"
    />
    <path
      d="M31.059 12.144c1.014 0 1.835.857 1.835 1.914s-.821 1.914-1.835 1.914-1.836-.857-1.836-1.914.822-1.914 1.836-1.914z"
      fill="#F07281"
    />
    <path
      d="M43.558 3.77h-3.503c-.38 0-.689.32-.689.717 0 .396.308.718.689.718h3.503c1.139 0 2.065.966 2.065 2.153V29.61H14.78c-.38 0-.688.321-.688.718 0 .396.308.718.688.718h30.843v1.196c0 1.187-.926 2.153-2.065 2.153H3.442c-1.139 0-2.065-.966-2.065-2.153v-1.196h10.402c.38 0 .689-.322.689-.718 0-.397-.308-.718-.689-.718H1.377V7.36c0-1.188.926-2.154 2.065-2.154h33.613c.38 0 .688-.322.688-.718 0-.396-.308-.718-.688-.718H3.442C1.544 3.77 0 5.38 0 7.36V32.24c0 1.979 1.544 3.589 3.442 3.589h13.862v4.306h-5.279c-1.139 0-2.065.966-2.065 2.154 0 1.187.926 2.153 2.065 2.153h22.95c1.139 0 2.065-.966 2.065-2.153 0-1.188-.926-2.154-2.065-2.154h-5.279v-.526c0-.396-.308-.718-.688-.718-.38 0-.689.322-.689.718v.526h-9.638V35.83h9.638v.652c0 .396.309.717.689.717.38 0 .688-.321.688-.717v-.652h13.862c1.898 0 3.442-1.61 3.442-3.589V7.358c0-1.979-1.544-3.589-3.442-3.589zm-14.55 37.802h5.967c.38 0 .688.322.688.718 0 .396-.309.718-.688.718h-22.95c-.38 0-.688-.322-.688-.718 0-.396.309-.718.688-.718h16.983z"
      fill="#000"
    />
    <path
      d="M42.438 26.782h-3.672c-.38 0-.689-.321-.689-.718 0-.396.309-.718.689-.718h2.01l-8.364-8.719a2.435 2.435 0 0 1-2.768-.04l-1.501 1.565c.152.339.238.716.238 1.114 0 1.451-1.133 2.632-2.525 2.632s-2.524-1.18-2.524-2.632c0-.37.074-.722.206-1.041l-4.091-4.265a2.43 2.43 0 0 1-1.256.35c-1.392 0-2.524-1.18-2.524-2.632 0-1.451 1.132-2.632 2.524-2.632 1.392 0 2.525 1.18 2.525 2.632 0 .454-.111.882-.306 1.255l4.004 4.175a2.437 2.437 0 0 1 2.824-.043l1.517-1.582a2.71 2.71 0 0 1-.221-1.075c0-1.452 1.132-2.632 2.524-2.632 1.392 0 2.525 1.18 2.525 2.632 0 .41-.091.8-.253 1.146l8.42 8.778v-2.096c0-.396.308-.718.688-.718.38 0 .688.322.688.718v3.828c0 .397-.308.718-.688.718zm-24.247-16.3c-.633 0-1.147.536-1.147 1.196 0 .66.514 1.196 1.147 1.196s1.148-.536 1.148-1.196c0-.66-.515-1.196-1.148-1.196zm6.518 8.784c0 .66.515 1.196 1.147 1.196.633 0 1.148-.537 1.148-1.196 0-.66-.515-1.196-1.148-1.196-.632 0-1.147.536-1.147 1.196zm6.35-6.055c-.634 0-1.148.537-1.148 1.197s.514 1.196 1.147 1.196 1.148-.537 1.148-1.196c0-.66-.515-1.197-1.148-1.197zM18.185 18.774H8.15c-.38 0-.689-.321-.689-.718 0-.396.309-.718.689-.718h10.036c.38 0 .689.322.689.718 0 .397-.308.718-.689.718zM18.185 21.9H8.15c-.38 0-.689-.321-.689-.717 0-.397.309-.718.689-.718h10.036c.38 0 .689.321.689.718 0 .396-.308.717-.689.717zM14.942 24.805H8.149c-.38 0-.689-.322-.689-.718 0-.396.309-.718.689-.718h6.793c.38 0 .688.322.688.718 0 .396-.308.718-.688.718z"
      fill="#000"
    />
  </Icon>
)

export default DemandManagementIcon
