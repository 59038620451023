import React from 'react'
import Modal from 'react-modal'
import cookieCutter from 'cookie-cutter'

const CookieConsent = ({ hasConsent, setHasConsent }) => {
  const allowConsent = () => {
    setHasConsent(true)
    var CookieDate = new Date()
    CookieDate.setFullYear(CookieDate.getFullYear() + 1)
    cookieCutter.set('consent_cookie', true, { expires: CookieDate })
  }

  const declineConsent = () => {
    setHasConsent(false)
    // var today = new Date()
    // var CookieDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
    var CookieDate = new Date()
    CookieDate.setFullYear(CookieDate.getFullYear() + 1)
    cookieCutter.set('consent_cookie', false, { expires: CookieDate })
  }

  return (
    <Modal className="Modal" isOpen={hasConsent === null} contentLabel="Modal">
      <div className="ThankYou column xy-center">
        <h2 className="text-primary my-24">Cookies!</h2>
        <h5 className="mb-16 text-primary text-center">We use cookies in order to personalize your experience!</h5>
        <div className="row y-center mt-32">
          <div className="link l mr-32" onClick={declineConsent}>
            Decline
          </div>
          <div className="button l" onClick={allowConsent}>
            Allow
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CookieConsent
