import React from 'react'
import { LoginForm } from '../../components/auction'
import { LogoAndTextIcon } from '../../components/icons'
import { View } from '../../components/ui'

const Login = () => {
  return (
    <View className="Login bg-grey row xy-center">
      <div className="login-form-wrapper p-32 column x-center">
        <LogoAndTextIcon width="360" />
        <LoginForm />
      </div>
    </View>
  )
}

export default Login
