import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

import { useLogin } from '../../utils/useAuth'
import { useSubmit } from '../../utils/useSubmit'
import SubmitButton from '../form/SubmitButton'
import FormStatus from '../form/FormStatus'

const LoginForm = () => {
  const history = useHistory()
  const { mutate: login } = useLogin()

  const submit = useSubmit()

  const initialValues = {
    identifier: '',
    password: '',
  }

  const validate = Yup.object().shape({
    identifier: Yup.string().required('Username or Email required'),
    password: Yup.string().required('Password required'),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validate}
      onSubmit={(values, formikBag) => {
        const onSuccess = (data) => {
          localStorage.setItem('token', data?.jwt)
          history.push('/auctions/')
        }

        submit({ values, formikBag, submitFn: login, success: onSuccess })
      }}
    >
      {(props) => (
        <Form className="login-form mt-48">
          <div className="error-message-wrapper">
            <ErrorMessage name="identifier" component="small" className="mb-8 error-message" />
          </div>
          <Field id="identifier" name="identifier" placeholder="Username or Email address" type="email" />
          <div className="error-message-wrapper">
            <ErrorMessage name="password" component="small" className="mb-8 error-message" />
          </div>
          <Field id="password" name="password" placeholder="Password" type="password" />
          <FormStatus {...props.status} />
          <SubmitButton label="Log In" labelSubmitting="Logging In" className="mt-32 l full-width" formik={props} />
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
