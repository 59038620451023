import React, { useState } from 'react'
import { motion } from 'framer-motion'
import Hamburger from '../icons/Hamburger'
import { NavLink } from 'react-router-dom'

const menu = {
  open: {
    clipPath: 'ellipse(200% 200% at 100% 0%)',
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
      velocity: 3,
    },
    pointerEvents: 'auto',
  },
  closed: {
    clipPath: 'ellipse(0% 0% at 100% 0%)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
    zIndex: -1,
    pointerEvents: 'none',
  },
}

const list = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 1 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const listItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const NavMenuMobile = ({ servicesMenu, data, language, changeLanguage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <div className="NavMenuMobile">
      <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Hamburger isMenuOpen={isMenuOpen} />
      </div>
      <motion.div className="menu" initial={false} animate={isMenuOpen ? 'open' : 'closed'} variants={menu}>
        <div className="menu-content">
          <motion.ul className="list" animate={isMenuOpen ? 'open' : 'closed'} variants={list}>
            <motion.li
              className="link l language-link"
              onClick={() => {
                changeLanguage(language === 'english' ? 'irish' : 'english')
                setIsMenuOpen(false)
              }}
            >
              {language === 'english' ? 'Gaeilge' : 'English'}
            </motion.li>
            <motion.li className="nav-item" variants={listItem}>
              <NavLink to="/" className="link l" onClick={() => setIsMenuOpen(false)}>
                {data?.home}
              </NavLink>
            </motion.li>
            <motion.li className="nav-item menu-title" variants={listItem}>
              <div to="/" className="link l">
                {data?.services}:
              </div>
            </motion.li>
            <div className="sub-menu">
              {servicesMenu.map(({ label, to }) => (
                <motion.li key={label} className="nav-item indent" variants={listItem}>
                  <NavLink className="link l" to={to} onClick={() => setIsMenuOpen(false)}>
                    {label}
                  </NavLink>
                </motion.li>
              ))}
            </div>
            <motion.li className="nav-item" variants={listItem}>
              <NavLink to="/about" className="link l" onClick={() => setIsMenuOpen(false)}>
                {data?.about}
              </NavLink>
            </motion.li>
            <motion.li className="nav-item" variants={listItem}>
              <NavLink to="/contact" className="button l" onClick={() => setIsMenuOpen(false)}>
                {data?.contact}
              </NavLink>
            </motion.li>
          </motion.ul>
        </div>
      </motion.div>
    </div>
  )
}

export default NavMenuMobile
