import React from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

const AnimateOnReveal = ({
  className,
  staggerChildren = 0.2,
  hidden = { y: 20, opacity: 0 },
  visible = { y: 0, opacity: 1 },
  transition,
  duration = 1,
  delay = 0,
  children,
  ...rest
}) => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true })

  const allChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest })
    }
  })

  const container = {
    hidden: { ...hidden },
    visible: {
      ...visible,
      // transition: { duration: 0.5, delayChilden: 0.2, staggerChildren: 0.1 },
      transition: {
        delay: delay,
        duration: duration,
        ease: 'easeOut',
        // duration: 0.75,
        // ease: [0.2, 0.6, 0.2, 1],
        // when: 'beforeChildren',
        staggerChildren: staggerChildren,
      },
    },
  }

  return (
    <motion.div
      className={className}
      ref={ref}
      variants={container}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      transition={transition}
      {...rest}
    >
      {allChildren}
    </motion.div>
  )
}

export default AnimateOnReveal
